import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";
import CardSchedule from "../../../Components/Cards/CardSchedule";
import Button from "../../Buttons/Button/Button";
import CardError from "../../../Components/Cards/CardError";

import "./ScheduleItemsInternal.scss";

export default function ScheduleItemsInternal({ busca }) {
  const [load, setLoad] = useState(true);
  const [plus, setPlus] = useState(4)
  const pagina = 1;
  const [idCategoria, setIdCategoria] = useState(1012);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoad(true);
    api
      .get(
        `posts/categoria?paginacao=true&itens=${plus}&pagina=${pagina}&categoria=${idCategoria}`,
      )
      .then((res) => {
        if (res.data && res.data.data && res.data.data.data) setData(res.data.data.data);
        setLoad(false);
      });
  }, [idCategoria, plus]);

  function handleOnLoadMore() {
    setPlus(plus + 4)
  }

  return (
    <div className="agenda" >
      <div className="buttons" style={{marginBottom: '12px'}}>
        <Button
          onClick={() => setIdCategoria(1012)}
          className={`btn ${
            idCategoria === 1012 ? "primary" : "outline gray dark"
          }`}
        >
          Exposições
        </Button>

        <Button
          onClick={() => setIdCategoria(1000)}
          className={`btn ${
            idCategoria === 1000 ? "primary" : "outline gray dark"
          }`}
        >
          Shows
        </Button>

        <Button
          onClick={() => setIdCategoria(22)}
          className={`btn ${
            idCategoria === 22 ? "primary" : "outline gray dark"
          }`}
        >
          Teatro
        </Button>

        <Button
          onClick={() => setIdCategoria(6)}
          className={`btn ${
            idCategoria === 6 ? "primary" : "outline gray dark"
          }`}
        >
          Cinema
        </Button>
      </div>

      <div className="list">
        {(data.length <= 0) & (load === false) ? (
          <CardError />
        ) : (
          <>
            {data.map((row, key) => (
              <CardSchedule
                key={key}
                data={{
                  data: row.data_post,
                  title: row.titulo,
                  link: "ler mais",
                  to: `/materia/${row.slug}`,
                  load: load,
                  image: row.imagem,
                }}
              />
            ))}
          </>
        )}

        {data.length > 0 && (
          <Button
            onClick={handleOnLoadMore}
            className={`btn primary`}
          >
            carregar mais notícias
          </Button>
        )}

      </div>
    </div>
  );
}
