
import React from 'react';
import ColumnControl from '../../Components/ColumnControl/ColumnControl';
import RowControl from '../../Components/RowControl/RowControl';
import WidgetControl from '../../Components/WidgetControl/WidgetControl';
import PodcastItems from '../../Components/Widgets/PodcastItems/PodcastItems';
import { useEffect } from 'react';
import { init, sendView } from '../../GA/ga4';

export default function Podcast(props) {
    useEffect(() => {
        // init()
        sendView("/podcast")
    }, [])

    return (
        <div id="podcast" className="page">

            <RowControl>
                <ColumnControl column={12}>
                    <WidgetControl title="Podcasts">
                        <PodcastItems />
                    </WidgetControl>
                </ColumnControl>
            </RowControl>

        </div>
    )

}