import React from 'react'

export default function IconPlay({id, size = 84, color = "#FFF", onClick, isIconPagePlayer }){
    return isIconPagePlayer ? 
    (
        <svg id={id} xmlns="http://www.w3.org/2000/svg" width={14} height={14} preserveAspectRatio="xMidYMid meet" viewBox="0 0 18 18">
            <g fill={"#252428"} >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
            </g>
        </svg>
    ) : 
    (
        <svg style={{cursor: 'pointer', filter: 'drop-shadow( 1px 1px 10px #000'}} id={id} onClick={onClick} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width={size} height={size} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
            <g fill={color} >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
            </g>
        </svg>
    )
}