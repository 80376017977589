import React, { useCallback, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../Api/app";
import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import ModalMessage from "../../ModalMessage/ModalMessage";
import InputDefault from "../../Buttons/InputDefault";
import ButtonDefault from "../../Buttons/ButtonDefault";

import "./styles.scss";

export default function NewPassword() {
  const params = useParams();
  const formRef = useRef(null);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: ''
  });
  const [modal, setModal] = useState(false);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          senha: Yup.string().required("Senha obrigatório"),
          confirmeSenha: Yup.string().required("Confirmação obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true)

        const {
          senha,
          confirmeSenha,
        } = data;
        const formData = {
          senha,
          confirmeSenha,
          token: params.token
        };

        const response = await api.post("/usuarios/senha/alterar", formData);

        if (response.data.status === "error") {
          throw response.data.message;
        }
        
        setError({
          status: response.data.status,
          message: response.data.message
        })

        setModal(!modal)

        setLoading(false)
        setTimeout(() => {
          setModal(false)
          history.push('/')
        }, 5000)

      } catch (err) {
        setLoading(false)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);
          return;
        }
        setError({
          status: 'error',
          message: err
        })
        setModal(!modal)
      }
    },
    [history, modal, params],
  );

  return (
    <div className="containerSignIn dark">
      <div className="profileRegister">
        <h1 className="profileDark">Cadastrar nova senha</h1>
        <div className="contentSignIn dark">
          <Form ref={formRef} onSubmit={handleSubmit} onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}>

            <div className="field">
              <InputDefault name="senha" label="Senha" placeholder="Senha" type="password" />
              <InputDefault name="confirmeSenha" label="Repita a senha" placeholder="Repita a senha" type="password" />
            </div>

            <ButtonDefault
              type="submit"
              buttonType="buttonDefault"
              loading={loading}
            >
              Cadastrar
            </ButtonDefault>
          </Form>
        </div>
      </div>

      <ModalMessage
        message={error.message}
        id="overlayModalMessage"
        onClose={() => setModal(!modal)}
        messageButton={error.status === 'success' ? 'Sucesso' : 'Tentar Novamente'}
        modal={modal}
        error={error}
      />
    </div>
  );
}
