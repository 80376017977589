import React from "react";
import { Link } from "react-router-dom";
import ImageAsync from "react-image-async";
import Skeleton from "react-loading-skeleton";
import Datetime from "../../../Helper/Datetime";

import ErrorBusca from "../../../Images/ErrorBusca.jpg";

import "./styles.scss";
import moment from "moment";

export default function CardImage({ data }) {
  const SRC = `${
    data.src ? data.src : "https://beta.alphafm.com.br/uploads/blog/"
  }`;

  return (
    <div id="containerCardImage">
      <Link to={data.to} className="cardImage cardImageDark">
        <ImageAsync src={SRC}>
          {({ error }) =>
            error ? (
              <div
                className="imagess imageDark"
                style={{ height: data.height ? data.height : "" }}
              >
                {data.load === false ? (
                  <img
                    src={`${data.podcast ? data.podcast : SRC + data.image}`}
                    alt="default"
                  />
                ) : (
                  <Skeleton
                    width="100%"
                    height={`${data.height ? data.height : "100%"}`}
                  />
                )}
              </div>
            ) : (
              <div className="imagess filterDark imageDark">
                {data.load === false ? (
                  <img
                    src={`${ErrorBusca}`}
                    style={{ objectPosition: "center" }}
                    alt="default"
                  />
                ) : (
                  <Skeleton width="100%" height="100%" />
                )}
              </div>
            )
          }
        </ImageAsync>

        {data.data && (
          <span className="cardDate">
            {data.load === false ? (
            
              moment(data.data)
                .format('DD/MM/YYYY HH:mm' )
             
            ) : (
              <Skeleton height="100%" />
            )}
          </span>
        )}

        <h2 className={`cardTitle ${!data.data ? "notData" : ""}`}>
          {data.load === false ? data.title : <Skeleton height="100%" />}
        </h2>

        {data.subtitle && (
          <h3 className="cardSubTitle">
            {data.load === false ? data.subtitle : <Skeleton height="100%" />}
          </h3>
        )}

        <span className="cardLink imageDark">
          {data.load === false ? data.link : <Skeleton height="100%" />}
        </span>

      </Link>
    </div>
  );
}
