import React from "react";
import NotFound from "../../Icons/NotFound";

import "./styles.scss";

export default function CardError({ isErrorCategory }) {
  return (
    <div id="containerCardError" className={`containerCardErrorDark ${isErrorCategory && 'isErrorCategory'}`}>
      {isErrorCategory ? (
        <>
          <h2 className="cardErrorTitle">NÃO ENCONTRAMOS NADA POR AQUI...</h2>
          <h3 className="cardErrorSubtitle">
            QUE TAL ESCOLHER OUTRA CATEGORIA?
          </h3>
          <div className="imagess">
            <NotFound />
          </div>
        </>
      ) : (
        <>
          <div className="imagess">
            <NotFound />
          </div>

          <h2 className="cardErrorTitle">NÃO ENCONTRAMOS NADA POR AQUI...</h2>

          <h3 className="cardErrorSubtitle">
            QUE TAL ESCOLHER OUTRA CATEGORIA?
          </h3>
        </>
      )}
    </div>
  );
}
