import React, { useState, useEffect } from "react";
import ColumnControl from "../../../Components/ColumnControl/ColumnControl";
import RowControl from "../../../Components/RowControl/RowControl";
import WidgetControl from "../../../Components/WidgetControl/WidgetControl";
import { api } from "../../../Api/app";
import ReadMore from "../../../Components/Widgets/ReadMore/ReadMore";
import DetailedNews from "../../../Components/Widgets/DetailedNews/DetailedNews";
import ScheduleItemsInternal from "../../../Components/Widgets/ScheduleItemsInternal/ScheduleItemsInternal";
import { init, sendView } from "../../../GA/ga4";

export default function ScheduleInternal(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [dataMore, setDataMore] = useState([{}]);
  const [plus, setPlus] = useState(3)

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, plus]);

  useEffect(() => {
    // init()
    sendView(`/agenda/${props.match.params.slug}`)
  }, [props.match.params.slug])

  async function getData() {
    setLoad(true);
    const response = await api.get(`posts/slug/${props.match.params.slug}`);
    setData(response.data.data);
    const responseMore = await api.get(
      `posts/categoria?paginacao=true&itens=${plus}&categoria=${1001}`,
    );
    setDataMore(
      responseMore.data.data.data.filter(
        (obj) => obj.id_categoria !== response.data.data.id_categoria,
      ),
    );
    setLoad(false);
  }

  return (
    <div id="scheduleInternal" className="page">
      <RowControl>
        <ColumnControl column={8}>
          <WidgetControl title={data.titulo} load={load}>
            <DetailedNews load={load} data={data} />
          </WidgetControl>
        </ColumnControl>

        <ColumnControl column={4}>
          {dataMore.length > 0 && (
            <WidgetControl title="Leia também">
              <ReadMore 
                load={load} 
                data={dataMore}
                handleOnLoadMore={() => setPlus(plus + 3)}
              />
            </WidgetControl>
          )}

          <WidgetControl title="Agenda">
            <ScheduleItemsInternal />
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
