import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";
import OverlayPlay from "../../OverlayPlay/OverlayPlay";
import Datetime from "../../../Helper/Datetime";
import Skeleton from "react-loading-skeleton";
import CardError from "../../../Components/Cards/CardError";

//IMAGENS

import "./Youtube.scss";
import moment from "moment";

export default function Youtube({ busca }) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([{ video: "/embed/" }]);

  useEffect(() => {
    setLoad(true);
    api.get(`videos-youtube?busca=${busca}`).then((res) => {
      if (res.data && res.data.data)
        setData(res.data.data);
      setLoad(false);
    });
  }, [busca]);


  return (
    <div className="youtube">
      {data.length > 0 && (
        <>
          <a
            target="_blank"
            href={load === false ? `${data[0].video}` : "/"}
            className="destaque"
          >
            {load === false ? (
              <img
                className="wallpaper"
                src={`https://i.ytimg.com/vi/${
                  data[0].video.split("/embed/")[1].includes('si=') ? data[0].video.split("/embed/")[1].split('?si=')[0] : ''
                  // data[0].video.split("/embed/")[1].includes('si=') ? '' : data[0].video.split("/embed/")[1]
                  }/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCCuKu6YCfdcdqWkJrZ4xj4h9tiUA`}
                alt="wallpaper"
              />

            ) : (
              <Skeleton height="200px" />
            )}
            <OverlayPlay
              // onClick={() => window.open(data[0].video, "_blank")}
              size={84}
            />
          </a>
          <a
            target="_blank"
            href={load === false ? `${data[0].video}` : "/"}
          >
            <div className="textDestaque">
              {load === false ? (
                // Datetime.string(data[0].criado)
                moment(data[0].criado)
                  .utc()
                  .format('DD/MM/YYYY HH:mm')
              ) : (
                <Skeleton />
              )}
              <b>{load === false ? data[0].titulo : <Skeleton />}</b>
            </div>
          </a>
        </>
      )}

      <div className="list">
        {(data.length <= 0) & (load === false) ? (
          <CardError />
        ) : (
          <>
            {data.map(
              (row, key) =>
                key > 0 && (
                  <a
                    target="_blank"
                    href={load === false ? `${row.video}` : '/'}
                    className="item"
                    key={key}
                  >
                    {/* <div
                      className="image"
                      style={{
                        backgroundImage: `url(https://i.ytimg.com/vi/${row.video.split("/embed/")[1]}/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCCuKu6YCfdcdqWkJrZ4xj4h9tiUA)`,
                      }}
                    > */}
                    <div className="image">
                      <img
                        className="image"
                        // width={120}
                        style={{ maxWidth: '109px', maxHeight: '63px' }}
                        // src={`https://i.ytimg.com/vi/${row.video.split("/embed/")[1]}/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCCuKu6YCfdcdqWkJrZ4xj4h9tiUA`}
                        // src={`https://i.ytimg.com/vi/${row.video.split("/embed/")[1].includes('si=') ? row.video.split("/embed/")[1].split('?si=')[0] : ''}/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCCuKu6YCfdcdqWkJrZ4xj4h9tiUA`}
                        src={`https://i.ytimg.com/vi/${row.video.split("/embed/")[1].split('?si=')[0]}/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCCuKu6YCfdcdqWkJrZ4xj4h9tiUA`}
                        alt="TESTE"
                        onError={(({ currentTarget }) => {
                          currentTarget.src = `https://i.ytimg.com/vi/${row.video.split("/embed/")[1].includes('si=') ? row.video.split("/embed/")[1].split('?si=')[0] : ''}/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCCuKu6YCfdcdqWkJrZ4xj4h9tiUA`;
                          currentTarget.onerror = null; // prevents looping
                        })}
                      />
                      {load === true && <Skeleton height="100%" />}
                      <OverlayPlay size={50} />
                    </div>

                    <div className="text">
                      {load === false ? (
                        // Datetime.string(row.criado)
                        moment(row.criado)
                          .format('DD/MM/YYYY HH:mm')
                      ) : (
                        <Skeleton />
                      )}
                      <b>{load === false ? row.titulo : <Skeleton />}</b>
                    </div>
                  </a>
                ),
            )}
          </>
        )}
      </div>
    </div>
  );
}