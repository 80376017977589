import React from "react";
import IconLoading from "../../Icons/IconLoading";

import "./styles.scss";

const ButtonDefault = ({ children, buttonType, loading, disabled, ...rest }) => {
  return (
    <button
      disabled={disabled}
      className={`buttonDefault
        ${loading ? 'isLoading' : ''}
        ${buttonType === 'register' ? 'buttonRegister' : ''}
        ${disabled === true ? 'disabled' : ''}
      `}
      {...rest}
    >
      {loading ? <IconLoading /> : children}
    </button>
  );
};

export default ButtonDefault;
