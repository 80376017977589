import React, { useCallback, useRef, useState } from "react";
import { api } from "../../../Api/app";
import { Form } from "@unform/web";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useAuth } from "../../../Context/AuthContext";

import InputDefault from "../../Buttons/InputDefault";
import SelectDefault from "../../Buttons/SelectDefault";
import IconCamera from "../../Icons/IconCamera";
import UserImg from "../../../Images/UserImg.png";

import "./styles.scss";
import { useEffect } from "react";
import ButtonDefault from "../../Buttons/ButtonDefault";

export default function Profile() {
  const formRef = useRef(null);
  const history = useHistory()
  const { userAlpha, updateUser } = useAuth();
  const [address, setAddress] = useState(false);
  const [image, setImage] = useState();
  const [load, setload] = useState(false)

  useEffect(() => {
    setImage(userAlpha.foto)
  }, [userAlpha])

  function onBlurCep(event) {
    const { value } = event.target;
    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setAddress({ ...data });
      });
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const img = image;
        Object.assign(data, { image: img || "" });

        const schema = Yup.object().shape({
          nome: Yup.string().required("Nome obrigatório"),
          email: Yup.string()
            .required("Email obrigatório")
            .email("Digite e-mail valido"),
          cpf: Yup.string().required("CPF obrigatório"),
          telefone: Yup.string().required("Telefone obrigatório"),
          data_nascimento: Yup.string().required("Data nascimento obrigatório"),
          sexo: Yup.string().required("Sexo obrigatório"),
          cep: Yup.string().required("CEP obrigatório"),
          endereco: Yup.string().required("Endereço obrigatório"),
          bairro: Yup.string().required("Bairro obrigatório"),
          estado: Yup.string().required("UF obrigatório"),
          cidade: Yup.string().required("Cidade obrigatório"),
          imagem: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setload(true)

        const {
          nome,
          cpf,
          telefone,
          data_nascimento,
          sexo,
          cep,
          endereco,
          bairro,
          complemento,
          estado,
          cidade,
        } = data;

        const formData = {
          nome,
          cpf,
          telefone,
          cep,
          endereco,
          complemento,
          bairro,
          estado,
          cidade,
          data_nascimento,
          sexo,
        };

        formData.foto = image;

        const response = await api.put("/usuarios/editar", formData);

        setload(false)

        if (response.data.status === "error") {
          throw response.data.message;
        }

        history.push('/')
        window.location.reload();
      } catch (err) {
        setload(false)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);
          return;
        }
      }
    },
    [image, history]
  );

  const handleAvatarChange = useCallback((e) => {
    if (e.target.files) {
      const data = new FormData();
      data.append("imagem", e.target.files[0]);
      api.post("/usuarios/upload", data).then((response) => {
        setImage(response.data.url);
        updateUser({...userAlpha, image})
      });
    }
  }, [image, updateUser, userAlpha]);

  return (
    <div className="containerSignIn dark">
      <div className="profile">
        <h1 className="profileTitle">Imagem</h1>
        <div className="containerPerfil">
          <div className="imageProfile">
            <img
              src={userAlpha.foto ? userAlpha.foto : UserImg}
              alt={userAlpha.nome}
            />

            <label htmlFor="foto" className="profilePhoto">
              <IconCamera />
              <input type="file" id="foto" onChange={handleAvatarChange} />
            </label>

            <h2 className="dark">{userAlpha.nome}</h2>
          </div>
        </div>
      </div>

      <div className="profileData">
        <h1 className="profileDark">Dados do perfil</h1>
        <div className="contentSignIn dark">
          <Form
            initialData={{
              nome: userAlpha.nome,
              email: userAlpha.email,
              cpf: userAlpha.cpf,
              telefone: userAlpha.telefone,
              data_nascimento: userAlpha.data_nascimento,
              sexo: userAlpha.sexo,
              cep: userAlpha.cep,
              endereco: userAlpha.endereco,
              bairro: userAlpha.bairro,
              complemento: userAlpha.complemento,
              uf: userAlpha.estado,
              cidade: userAlpha.cidade,
            }}
            ref={formRef}
            onSubmit={handleSubmit}>
            <div className="field">
              <InputDefault name="nome" label="Nome" placeholder="Nome" />
              <InputDefault name="email" label="Email" placeholder="Email" />
            </div>

            <div className="fieldGroup">
              <InputDefault
                name="cpf"
                mask="cpf"
                label="CPF"
                placeholder="CPF"
              />
              <InputDefault
                name="telefone"
                mask="fone"
                label="Telefone"
                placeholder="Telefone"
              />
            </div>

            <div className="field">
              <InputDefault
                type="file"
                name="imagem"
                label="Imagem"
                id="imagem"
                onChange={handleAvatarChange}
              />
            </div>

            <div className="fieldGroup">
              <InputDefault
                name="data_nascimento"
                mask="date"
                label="Data nascimento"
                placeholder="Data nascimento"
              />
              <SelectDefault name="sexo" label="Sexo" placeholder="Sexo">
                <option value="">Selecione sexo</option>
                <option value="Mascolino">masculino</option>
                <option value="Feminino">feminino</option>
                <option value="Prefiro não informar">
                  Prefiro não informar
                </option>
              </SelectDefault>
            </div>

            <div className="fieldGroup">
              <InputDefault
                name="cep"
                mask="cep"
                label="CEP"
                placeholder="CEP"
                onBlur={onBlurCep}
              />
              <InputDefault
                name="endereco"
                label="Endereço"
                placeholder="Endereço"
                // onChange={(event) => {
                //   console.log(event.target.value);
                //   setAddress((prev) => ({
                //     ...prev,
                //     logradouro: event.target.value,
                //   }));
                // }}
                // value={address?.logradouro}
              />
            </div>

            <div className="fieldGroup">
              <InputDefault
                name="bairro"
                label="Bairro"
                placeholder="Bairro"
                value={address?.bairro}
              />
              <InputDefault
                name="complemento"
                label="Complemento"
                placeholder="Complemento"
              />
            </div>

            <div className="fieldGroup">
              <InputDefault
                name="estado"
                label="UF"
                placeholder="UF"
                value={address?.uf}
              />
              <InputDefault
                name="cidade"
                label="Cidade"
                placeholder="Cidade"
                value={address?.localidade}
              />
            </div>

            <ButtonDefault type="submit" buttonType="default" loading={load}>
              Atualizar dados
            </ButtonDefault>

            {/* <button className="buttonRegister" type="submit">
              Atualizar dados
            </button> */}
          </Form>
        </div>
      </div>
    </div>
  );
}
