import React from "react";
import "./ModalDefault.scss";

export default function ModalDefault({
  id = "overlayModalDefault",
  onClose = () => {},
  children
}) {
  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  return (
    <div id={id} className="overlayModalDefault" onClick={handleOutsideClick}>
        {children}
    </div>
  );
}
