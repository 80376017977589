import React from "react";
import CloseMenu from "../Icons/CloseMenu";

import "./ModalTerms.scss"

export default function ModalTerms({ id = "overlayModal", onClose = () => {}, }) {
    const handleOutsideClick = (e) => {
        if (e.target.id === id) onClose();
      };

    return (
        <div id={id} className="overlayModal" onClick={handleOutsideClick}>
        
            <div className="modalTerms">
                <div className="top">
                    <h2>
                        POLÍTICA DE SEGURANÇA E PRIVACIDADE
                    </h2>
                    <div
                        onClick={onClose}
                        className="closeModal"
                    >
                        <CloseMenu size={36} />
                    </div>                    
                </div>
                <div className="terms">
                    <span className="spanDark">
                        <p>A alphafm.com.br entende a importância e preocupação que nossos ouvintes tem com sua segurança online e de seus seus dados pessoais. Por isso, nos preocupamos em esclarecer e divulgar nossa política de utilização dessas informações. Assim, você poderá entender melhor quais informações obtemos e como as utilizamos.</p>

                        <p>Reserve alguns minutos do seu tempo para ler essa política e aproveitar de forma consciente e segura o que o site da Alpha FM tem para você. A Alpha fm pode alterar essa política de tempos em tempos para adequar as conformidades legais e sendo assim, recomenda sua leitura periodicamente.</p>
                        
                        <br></br>
                        <p><b>Quais informações que obtemos</b></p>

                        <p>                        
                        Coletamos informações de diversas formas, algumas informações pessoais são obtidas quando você se registra. Durante o processo de registro, perguntamos seu nome, endereço de e-mail e outras informações pessoais. A veracidade destas informações nos dão a possibilidade de entrar em contato com você para passarmos informações de promoções, show e aviso de prêmios.</p>

                        <p>O ouvinte e/ou usuário que infringir nossa política ou fornecer dados enganosos poderá ter o seu login cancelado e bloqueado sem aviso prévio e a critério exclusivo da Alpha FM.</p>

                        <p>Após seu registro, você estará apto a usufruir e desfrutar de nossas promoções, matérias e informações.</p>

                        <p>Possuímos diversos parceiros e/ou patrocinadores, que também criam sorteios e promoções, oferecendo inúmeros bens e/ou serviços através de nossas sessões no site. As informações que você fornece quando visita e/ou participa de promoções são obtidas por esses parceiros e/ou patrocinadores, estando assim sujeitas às suas próprias práticas de obtenção e uso de dados, sem que caiba qualquer responsabilidade à Alphafm.com.br pelo manuseio de tais informações.</p>

                        <p>Para obter informações sobre as políticas e normas de privacidade desses parceiros e/ou patrocinadores, sugerimos que você localize as normas de relacionamento específicas dentro do próprio site do parceiro.</p>

                        <br></br><p>
                        <b>Utilização de "cookies"</b>
                        </p>

                        <p>Cookies são pequenos arquivos de texto enviados ao seu computador. Os cookies são armazenados no seu computador. Estes pequenos arquivos servem para reconhecer, acompanhar e armazenar a navegação do usuário na Internet.</p>

                        <p>O uso de cookies para acompanhar e armazenar informações possibilitará a Alphafm.com.br oferecer um serviço mais E de acordo com as características e interesses de nossos ouvintes, possibilitando, inclusive, a oferta de conteúdo e publicidade específicos para cada pessoa, beneficiando a experiência do usuário na Internet.</p>

                        <p>Em geral, os cookies são utilizados para: Proporcionar serviços diferenciados, lembrando quem você é e quais são os seus hábitos de navegação, além de acessar as informações do seu cadastro na Alphafm.com.br (armazenadas em nossos servidores);</p>

                        <p>Eles servem para acompanhar o andamento de promoções. Quando uma promoção organizada pela Alphafm.com.br e seus subdomínios utilizam cookies, as informações gravadas no cookie indicam a pontuação do usuário e outros recursos.</p>

                        <p>Medir certos padrões de navegação, mapeando quais áreas você visitou e seus hábitos de visita como um todo. Usamos essa informação para verificar a rotina de navegação dos nossos usuários/ouvintes, e assim oferecer conteúdo e/ou serviços cada vez mais personalizados.</p>

                        <p>Facilitar e agilizar o preenchimento de formulários. As informações contidas nos cookies de cada usuário podem ser utilizadas para preencher previamente os formulários de coleta de dados existentes na Internet.</p>

                        <p>Os anunciantes, parceiros e/ou patrocinadores da Alphafm.com.br poderão utilizar seus próprios cookies, os quais não são cobertos por esta política. Sugerimos que você consulte as políticas de privacidade dessas empresas.</p>

                        <br></br><p>
                        <b>Como utilizaremos estas informações</b>
                        </p>                        

                        <p>As informações coletadas pela Alphafm.com.br serão utilizadas para personalizar o conteúdo e/ou serviços disponibilizados. Isso inclui serviços de personalização, comunicações interativas, compras on-line e outros tipos de serviços. Sabendo mais sobre você, a Alphafm.com.br pode fornecer conteúdo e serviços mais relevantes e, conseqüentemente, proporcionar uma melhor experiência on-line.</p>

                        <p>As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial. Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários/ouvintes da Alphafm.com.br e poderão ser fornecidas a parceiros, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação.</p>

                        <p>As informações coletadas poderão ser utilizadas para direcionamento de campanhas publicitárias de uma forma geral, como por exemplo no caso em que um anunciante determina como foco de uma determinada campanha homens com mais de 25 anos.</p>

                        <p>Nesse caso, veiculamos a propaganda aos usuários dentro dessa faixa etária utilizando banners e/ou e-mails promocionais. Os usuários ao se cadastrarem na Alphafm.com.br terão a opção de receber ou não em sua conta de e-mail ofertas e/ou propaganda, sendo que, a qualquer tempo, essa opção poderá ser alterada pelo usuário.</p>

                        <p>As informações pessoais individuais não serão comercializadas ou fornecidas a terceiros em nenhuma hipótese, salvo em estrito cumprimento de ordens judiciais ou procedimentos jurídicos similares. Todas as informações fornecidas a parceiros, patrocinadores, anunciantes e demais serão apenas de grupos de usuários (segmentos de mercado).</p>

                        <p>As promoções realizadas na Alphafm.com.br podem ser patrocinadas por outras empresas ou segundo uma parceria entre a Alphafm.com.br e uma dessas empresas. Alguns ou todos os dados coletados durante uma promoção podem ser compartilhados com o parceiro e/ou patrocinador. Se os dados forem compartilhados, você será notificado antes da coleta ou transferência dos dados. Assim, poderá decidir não participar da promoção se não desejar que seus dados sejam compartilhados.</p>

                        <p>Sempre que você acessar anunciantes ou outros Sites da Web através de links presentes no site da Alphafm.combr, aqueles poderão coletar informações sobre você. Lembramos que as práticas relativas à privacidade desses parceiros e terceiros não são cobertos pela presente política e podem ser bem diferentes dos padrões de privacidade da Alphafm.com.br</p>

                        <p>Outros: A Alphafm.com.br também pode divulgar informações sobre o usuário em casos especiais nos quais temos razão para acreditar que a divulgação dessas informações é necessária para identificar, entrar em contato ou agir legalmente contra alguém que possa estar causando danos ou interferindo (intencional ou não intencionalmente) nos direitos ou na propriedade da Alphafm.com.br, de outros usuários da Alphafm.com.br ou de qualquer pessoa que seja prejudicada por tais atividades. A Alphafm.com.br pode divulgar informações sobre o usuário quando exigido por lei e com objetivos administrativos ou outros que considerarmos necessários para manter e aprimorar nossos produtos e serviços.</p>

                        <p>Se, ao se registrar, você indicou que está interessado em receber ofertas ou informações da Alphafm.combr e nossos parceiros, poderemos ocasionalmente enviar a você mensagens, via e-mail, sms ou outro canal de comunicação sobre produtos e serviços que possam interessá-lo. Somente a Alphafm.com.br (ou agentes que trabalhem em nome da Alphafm.com.br e sob acordos de confidencialidade) enviarão a você essas comunicações e somente se você indicou que não tem objeção contra essas ofertas. Se não desejar receber mais esses informes, basta dizer-nos ao fornecer suas informações pessoais, ou, a qualquer momento, alterar suas informações cadastrais, selecionando a opção de não receber mais essas comunicações. Serão necessárias sua identificação e senha de acesso ao cadastro da Alphafm.com.br para acessar suas informações cadastrais.</p>

                        <p>Para alguns tipos de promoções, solicitaremos seu endereço de e-mail para atualizá-lo sobre a sua pontuação ou alguma outra mecânica como suas participações e status nas premiações.</p>

                        <p>Ao participar desse tipo de promoção, você automaticamente recebe mensagens relacionadas à promoção, a menos que escolha a opção sem e-mail oferecida por algumas delas. Ocasionalmente, essas mensagens de e-mail relacionadas à promoção podem incluir anúncios direcionados. Se uma determinada promoção não oferecer uma opção de não recebimento de e-mail, você poderá optar por não receber e-mails, mas, conseqüentemente você deixa de participar dessa promoção.</p>

                        <p>Você também tem opções em relação aos cookies. Modificando suas preferências de navegação, você tem a opção de aceitar todos os cookies, ser notificado quando um cookie for enviado ou rejeitar todos os cookies. Se você optar por rejeitar todos os cookies, não conseguirá usar alguns dos serviços da Alphafm.com.br que requerem registro para participação. Também não poderá usufruir de todos benefícios e facilidades de uma experiência personalizada na Internet.</p>

                        <p>Utilizamos a criptografia padrão da indústria, SSL (Secure Sockets Layer, camada de soquetes de segurança).</p>

                        <p>As informações sobre a conta da Alphafm.com.br são protegidas por senha, para que somente você tenha acesso a suas informações pessoais.</p>

                        <p>Sua senha é secreta e recomendamos que não a revele a ninguém. A Alpha.com.br não irá solicitar, em nenhuma hipótese, seja por e-mail ou telefone, sua senha pessoal. Lembre-se também de desconectar-se da sua conta e fechar a janela do seu navegador quando concluir sua navegação na Internet. Isso garante que outras pessoas não tenham acesso às suas informações pessoais e correspondências, caso você compartilhe um computador com alguém ou esteja usando um computador em local público como uma biblioteca ou local de acesso público à Internet.</p>

                        <p>Nenhuma transmissão de dados na Internet é 100% segura. Sendo assim, embora a Alphafm.com.br sempre faça o possível para proteger suas informações pessoais, não é possível garantir a segurança de todas as informações que você venha a nos fornecer. É uma decisão pessoal a utilização do serviço nessas condições.</p>

                        <br></br><p>
                        <b>Mais informações sobre sua privacidade on-line</b>
                        </p>                    
                        
                        <p>Em última análise você é o responsável pela manutenção de sua privacidade e pelo sigilo de suas senhas e/ou informações pessoais.</p>

                        <p>Sendo assim, recomendamos:</p>

                        <p>Informe-se: ao acessar a Internet sempre busque a política/declaração de privacidade do Site que você está acessando.</p>

                        <p>Use senhas complexas. Senhas simples são fáceis de serem quebradas. Use senhas complexas de combinações bastante longas de letras e números que necessitam combinações pouco usuais do teclado.</p>

                        <p>Nunca forneça sua senha a ninguém on-line. Apenas forneça sua senha ao realizar compras pessoalmente. Se sua senha foi fornecida, mude-a imediatamente.</p>

                        <p>Anúncios pessoais e salas de bate-papo são de domínio público. Qualquer informação que você torne disponível em salas de bate-papo, em fóruns ou em anúncios pessoais estará disponível a qualquer pessoa com acesso a esses locais. Sugerimos que você evite incluir informações que permitam às pessoas encontrá-lo, tais como o número de seu telefone pessoal e redes sociais.</p>

                        <p>Desejamos a você ouvinte uma ótima experiência e seja bem vindo.</p>

                        <p>Alpha FM.</p>
                    </span>
                </div>
            </div>
            
        </div>
    )
}