import React from "react";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";

import "./ColumnistsPodcast.scss";

export default function ColumnistsPodcast({ data = [], load = true }) {
  const SRC = `https://beta.alphafm.com.br/uploads/programas/`;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          property="og:url"
          content={`https://alphafm.com.br/colunista/${data.slug}`}
        />
        <meta property="og:type" content="Colunista" />
        <meta property="og:title" content={data.colunista} />
        <meta property="og:description" content={data.colunista} />
        <meta property="og:image" content={`${SRC + data.imagem_principal}`} />

        <title>Colunista</title>
        <link
          rel="canonical"
          href={`https://alphafm.com.br/colunista/${data.slug}`}
        />
      </Helmet>
      <div className="columnistsPodcast">
        {load === false ? (
          <div className="imagess">
            <img src={`${SRC + data.imagem_principal}`} alt={data.colunista} />
          </div>
        ) : (
          <Skeleton width="100%" height="380px" />
        )}

        <div className="infos">
          <b>{load === false ? data.colunista : <Skeleton width="100%" />}</b>
          {load === false ? (
            <p dangerouslySetInnerHTML={{ __html: data.texto }}></p>
          ) : (
            <Skeleton width="100%" />
          )}
        </div>
      </div>
    </>
  );
}
