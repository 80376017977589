import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";
import CardImage from "../../../Components/Cards/CardImage";

import "./ColumnistsItems.scss";
import WhatsIcon from "../../Icons/WhatsIcon";
import { Helmet } from "react-helmet";

export default function ColumnistsItems() {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoad(true);
    api.get(`colunistas`).then((res) => {
      setData(res.data.data);
      setLoad(false);
    });
  }, []);

  function handleOnWhatsApp() {
    // window.location.href =
    //   "https://wa.me/5511945321017?text=Gostaria de perguntar para o: ";
    window.open("https://wa.me/5511945321017?text=Gostaria de perguntar para o: ");
  }

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content='https://alphafm.com.br/colunista' />
          <meta property="og:type" content='Colunistas' />
          <meta property="og:title" content='Colunistas' />
          <meta property="og:description" content='Venha conferir nossos Colunistas' />
          <meta property="og:image" content='https://scontent.fcgh17-1.fna.fbcdn.net/v/t39.30808-6/251985772_10158083644322155_2941830615724812929_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeGzf3iNk6jmchaL2pR9OWvOpfrLzFQUUF2l-svMVBRQXcP2WgFiA1NvETooFCEpbPmBwQo0c1GJtPQjmSsL_IsG&_nc_ohc=zErsoQe3eFsAX-8vmK8&_nc_ht=scontent.fcgh17-1.fna&oh=546bc000270c519bd7717ce6f120f8ae&oe=61B40840' />
          
          <title>Colunistas</title>
          <link rel="canonical" href="https://alphafm.com.br/colunista" />
      </Helmet>
      <div className="columnistsItems">
        <div className="columnQuestion columnQuestionDark">
          <h2>Fique por dentro de tudo que acontece em nossa programação!</h2>

          <button className="buttonQuestion" onClick={handleOnWhatsApp}>
            Pergunte ao Colunista
            <WhatsIcon />
          </button>
        </div>

        <div className="list">
          {data.map((row, key) => (
            <CardImage
              key={key}
              data={{
                title: row.colunista,
                link: "ler mais",
                to: `/colunista/${row.slug}`,
                load: load,
                image: row.imagem_principal,
                src: `https://beta.alphafm.com.br/uploads/programas/`
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
}
