import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { api } from '../Api/app';

const AuthContext = createContext({});

function AuthProvider ({ children }) {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@Alpha:token');
    const user = localStorage.getItem('@Alpha:user');
    
    if (token && user) {
      return { token, user: JSON.parse(user) };
    }
    return {};
  });
  
  const [userAlpha, setUserAlpha] = useState({})
  
  const signIn = useCallback(async ({ login, senha }) => {
    const response = await api.post("usuarios/login", {
      login,
      senha,
    });

    if (response.data.message === "Usuario ou senha invalidos") {
      throw response.data.message;
    }

    const token = response.data.data;
    
    localStorage.setItem('@Alpha:token', token);
    localStorage.setItem('@Alpha:user', JSON.stringify({ login }));
    
    setData({ token });
    window.location.reload();
  }, []);

  useEffect(() => {
    getProfile();
  }, [data]);

  async function getProfile() {
    const response = await api.get(`usuarios/profile`);
    setUserAlpha(response.data.data);
  }
  
  const signOut = useCallback(() => {
    localStorage.removeItem('@Alpha:token');
    localStorage.removeItem('@Alpha:user');
    
    setData({});
  }, []);

  const updateUser = useCallback((data) => {
      setUserAlpha(data);
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, userAlpha, setUserAlpha, updateUser }}>
      {children}
    </AuthContext.Provider>
  )
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within as AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };