import React, { useEffect, useState } from "react";
import { api } from "../../Api/app";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import InputSearch from "../Buttons/InputSearch/InputSearch";
import MenuProfile from "../MenuProfile/MenuProfile";
import PlayerAlpha from "../PlayerAlpha/PlayerAlpha";
import PlayerAlphaMpb from "../PlayerAlphaMpb/PlayerAlphaMpb";
import Container from "./Container";
import Menu from "./Menu";
import { usePlay } from "../../Context/PlayContext";
import Cookie from "../Cookie/Cookie";
import ReactGA4 from 'react-ga4';

//scss
import "./Layout.scss";
import PlayerAlphaClassics from "../PlayerAlphaClassics/PlayerAlphaClassics";

export default function Layout(props) {
  const [page, setPage] = useState(props.location.pathname);
  const [darkMode, setDarkMode] = useState(false);
  const [menu, setMenu] = useState(false);
  const [posts, setPosts] = useState([]);

  const handleChange = () => {
    setDarkMode(!darkMode);
    if (darkMode) {
      localStorage.setItem("Theme", "lightMode");
    } else {
      localStorage.setItem("Theme", "darkMode");
    }
  };

  // useEffect(() => {
  //   setPage(props.location.pathname);
  //   scrollTop();

  //   ReactGA4.initialize([
  //     {
  //       trackingId: 'G-CH3MTGJZ08',
  //     },
  //     {
  //       trackingId: 'G-1HH2LDDS34',
  //     },
  //     {
  //       trackingId: 'G-S9767L0R4Y',
  //     },
  //     {
  //       trackingId: 'UA-123901496-1',
  //     },
  //   ]);
  //   // To Report Page View 
  //   ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

  // }, [props]);

  useEffect(() => {
    const getTheme = localStorage.getItem("Theme");

    if (getTheme === "darkMode") {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }

    const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: light)");

    if (prefersDarkScheme.matches) {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }

    api
      .get("posts?paginacao=true&itens=10&pagina=1")
      .then((response) => {
        setPosts([...response.data.data.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function scrollTop() {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 1000);
  }

  return (
    <div
      id="layout"
      className={`${darkMode ? "darkMode" : "lightMode"} ${menu === true ? "layoutMobile" : ""
        }`}
    >
      {/* <Menu page={page} menu={menu} darkMode={darkMode} onCloseMenu={() => setMenu(!menu)} /> */}

      <Container page={page}>
        <header>
          <div className="players">
            {!(window.location.pathname ?? '').includes('/aovivo') ?
              (
                <>
                  <PlayerAlpha />
                  {/* <PlayerAlphaMpb /> */}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '12px', justifyContent: 'space-between' }}>
                    <PlayerAlphaClassics />
                    <PlayerAlphaMpb />
                  </div>
                </>
              ) : null
            }
          </div>

          {/* <InputSearch {...props} />

          <MenuProfile
            onCloseMenu={() => setMenu(!menu)}
            handleOnMode={handleChange}
            handleBuguer={() => { }}
            menu={menu}
            darkMode={darkMode}
          /> */}
        </header>

        {/* <div
          className={`navigation ${menu === true ? "navigationMobile" : ""}`}
        >
          <section>
            <Scrollbars
              className="scroll-section scroll-section-dark"
              autoHide={true}
            >
              {props.children}

              <footer>
                <div className="powered">
                  Powered by
                  <a
                    href="https://tropa.digital"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>Tropa Digital</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="img"
                      width="1em"
                      height="1em"
                      preserveAspectRatio="xMidYMid meet"
                      viewBox="0 0 256 256"
                    >
                      <path
                        d="M97.854 185.833c-5.04 15.12-17.163 26.264-35.057 32.228a88.28 88.28 0 0 1-25.307 4.449a4 4 0 0 1-4-4a88.277 88.277 0 0 1 4.448-25.307c5.965-17.894 17.11-30.017 32.229-35.057a4 4 0 1 1 2.53 7.59c-24.91 8.304-29.94 37.493-30.953 48.52c11.024-1.012 40.216-6.04 48.52-30.952a4 4 0 0 1 7.59 2.53zm100.856-77.632l-10.142 10.142v62.912a11.924 11.924 0 0 1-3.513 8.485l-32.343 32.342a12 12 0 0 1-20.252-6.132l-8.144-40.723l-43.543-43.542l-40.722-8.145a12 12 0 0 1-6.133-20.252L66.26 70.946a11.925 11.925 0 0 1 8.485-3.514h62.912l10.142-10.143c27.3-27.3 55.103-26.54 65.789-24.945a11.9 11.9 0 0 1 10.068 10.067c1.595 10.687 2.356 38.488-24.946 65.79zm-157.09 7.494l39.81 7.963l48.227-48.226H74.745a3.975 3.975 0 0 0-2.829 1.172l-32.342 32.34a4 4 0 0 0 2.045 6.751zm138.948 10.648l-48.226 48.227l7.962 39.81a4 4 0 0 0 6.752 2.045l32.341-32.342a3.974 3.974 0 0 0 1.171-2.828zm12.486-23.799c24.728-24.729 24.105-49.464 22.69-58.951a3.946 3.946 0 0 0-3.336-3.337c-9.489-1.415-34.224-2.038-58.953 22.69L142.142 74.26v.001L88.401 128L128 167.598l53.74-53.74z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>

                <marquee>
                  {posts.map((row, key) => (
                    <Link key={key} to={`/materia/${row.slug}`}>
                      {row.titulo}
                    </Link>
                  ))}
                </marquee>

                <div className="social">
                  <a
                    href="https://www.facebook.com/RadioAlphaFM"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/radioalphafm/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/AlphaFM1017"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                  <a
                    href="https://twitter.com/alphafmoficial"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                  <a
                    href="https://open.spotify.com/user/radioalphafm"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-spotify"></i>
                  </a>
                </div>
              </footer>
            </Scrollbars>
          </section>
        </div>

        <div className="whatsapp-icon">
          <a
            href="https://wa.me/5511945321017"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <Cookie /> */}

      </Container>
    </div>
  );
}
