
import React, { useState, useEffect } from 'react';
import ColumnControl from '../../../Components/ColumnControl/ColumnControl';
import RowControl from '../../../Components/RowControl/RowControl';
import WidgetControl from '../../../Components/WidgetControl/WidgetControl';
import PodcastInternalMusic from '../../../Components/Widgets/PodcastInternalMusic/PodcastInternalMusic';
import { Link } from 'react-router-dom'
import { api } from '../../../Api/app';
import { init, sendView } from '../../../GA/ga4';

export default function PodcastInternal(props) {
    const [load, setLoad] = useState(false)
    const [data, setData] = useState({
        id_podcast: ''
    })

    useEffect(() => {
        if (props.match.params.id) {
            setData({
                id_podcast: props.match.params.id,
            })
        }
    }, [props.match.params.id]);

    useEffect(() => {
        // init()
        sendView(`/podcast/${props.match.params.id}`)
    }, [props.match.params.id])

    return (
        <div id="podcastInternal" className="page">

            <RowControl>
                <ColumnControl column={12}>
                    <Link style={{ marginBottom: '10px', display: 'block' }} to="/podcast">Voltar para podcasts</Link>
                    <WidgetControl title={data.titulo}>
                        <PodcastInternalMusic data={data} load={load} />
                    </WidgetControl>
                </ColumnControl>
            </RowControl>

        </div>
    )

}