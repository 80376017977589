import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";
import Button from "../../Buttons/Button/Button";
import CardImage from "../../../Components/Cards/CardImage";
import "./PromotionResults.scss";

export default function PromotionResults() {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [plus, setPlus] = useState(2);

  useEffect(() => {
    setLoad(true);
    api
      .get(`promocoes/resultados?paginacao=true&itens=${plus}&pagina=1`)
      .then((res) => {
        if (res.data && res.data.data && res.data.data.data) setData(res.data.data.data);
        setLoad(false);
      });
  }, [plus]);

  function handleOnLoadMore() {
    setPlus(plus + 4)
  }

  return (
    <div className="promotionResults">
      {data.map((row, key) => (
        <CardImage
          key={key}
          data={{
            data: row.criado,
            title: row.promocao,
            link: "ler mais",
            to: `/promocoes/${row.slug}`,
            load: load,
            image: row.imagem,
            src: "https://beta.alphafm.com.br/uploads/promocoes/"
          }}
        />
      ))}

      {data.length > 0 && (
        <Button
          onClick={handleOnLoadMore}
          className={`btn primary`}
        >
          carregar mais resultados
        </Button>
      )}
    </div>
  );
}
