import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import getValidationErrors from "../../../utils/getValidationErrors";
import * as Yup from "yup";
import { useAuth } from "../../../Context/AuthContext";
import InputDefault from "../../Buttons/InputDefault";
import CloseMenu from "../../Icons/CloseMenu";
import ButtonDefault from "../../Buttons/ButtonDefault";
import ModalDefault from "../../ModalDefault/ModalDefault";
import CancelImg from "../../../Images/cancel.png";

import "./SignIn.scss";

export default function SignIn({ id = "overlayModal", onClose = () => {}, onForgotten = () => {} }) {
  const history = useHistory();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { signIn } = useAuth();
  const [modal, setModal] = useState(false);

  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  function handleOnRegister() {
    history.push("/cadastro");
    onClose();
  }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});

        const schema = Yup.object().shape({
          login: Yup.string()
            // .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
          senha: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await signIn({
          login: data.login,
          senha: data.senha,
        });

        setLoading(false);

        history.push("/");
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current.setErrors(errors);
          return;
        }
        setModal(true);
        // alert("Email/Senha invalidos");
      }
    },
    [signIn, history],
  );

  return (
    <div id={id} className="overlaySignIn dark" onClick={handleOutsideClick}>
      <Form className="formSignIn" ref={formRef} onSubmit={handleSubmit}>
        <h1>Login</h1>

        <div className="field">
          <InputDefault 
            name="login"
            label="Nome"
            placeholder="Nome" 
          />

          <InputDefault
            name="senha"
            type="password"
            label="Senha"
            placeholder="Senha"
          />
        </div>

        <div className="fieldForgot">
          <button 
            type="button"
            className="forgotPassword"
            onClick={onForgotten}
          >
            Esqueci a senha
          </button>
        </div>

        <div className="field">
          <ButtonDefault type="submit" loading={loading}>
            Entrar
          </ButtonDefault>

          <ButtonDefault
            type="button"
            onClick={handleOnRegister}
            buttonType="register"
            loading={loading}
          >
            Cadastrar
          </ButtonDefault>
        </div>

        <div onClick={onClose} className="closeModal">
          <CloseMenu size={36} />
        </div>
      </Form>

      {modal && (
        <ModalDefault id="overlayModalDefault" onClose={() => setModal(!modal)}>
          <div className="cardModal">
            <div className="cardContent">
              <div className="infoModal">
                <img src={CancelImg} alt="cancelar" />
                <h1 className="errorModal">Erro</h1>
                <span className="messageModal">Email ou senha invalidos</span>
              </div>
              <div className="buttonModal">
                <button
                  className="buttonCancel"
                  onClick={() => setModal(!modal)}
                >
                  Tentar novamente
                </button>
              </div>
            </div>
          </div>
        </ModalDefault>
      )}
    </div>
  );
}
