import InfiniteCarousel from 'react-leaf-carousel';

import React from "react";
import "./Banner.scss";

export default function Banner() {
  const bannerData = [
    {
      img: {
        uri: "https://beta.alphafm.com.br/uploads/promocoes/GIF-RADIO-2-min.gif",
        alt: "O Radio é Muziblaster"
      },
      link: "https://radiomaiscem.com.br",
      text: "O Radio é Muziblaster"
    },
    // {
    //   img: {
    //     uri: "https://beta.alphafm.com.br/uploads/promocoes/ADRIANA-CALCANHOTO_BANNER_SITE.png",
    //     alt: "Show da Adriana Calcanhotto - Alpha FM e Tokio Marine Hall"
    //   },
    //   link: "https://www.tokiomarinehall.com.br/adriana-calcanhotto-2",
    //   text: "Show da Adriana Calcanhotto"
    // },
    {
      img: {
        uri: "https://beta.alphafm.com.br/uploads/promocoes/DELAS-PRA-ELAS.png",
        alt: "Roda de Conversa - Festival Delas Para Elas"
      },
      link: "https://www.youtube.com/watch?v=6-9Az9MQkA4",
      text: "Festival Delas Para Elas"
    },
  ]


  const banner = Math.round(Math.random() * 10) % 2 === 0 ? bannerData[1] : bannerData[0]

  return (
    <InfiniteCarousel
    breakpoints={[
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ]}
    dots={true}
    showSides={false}
    sidesOpacity={0}
    sideSize={1}
    slidesPerRow={5}
    slidesToScroll={1}
    slidesToShow={1}
    scrollOnDevice={true}
    swipe={true}
    incrementalSides={true}
  
  >
   <div className='banner'>
      <a href={banner.link}>
      <img
        alt={banner.img.alt}
        src={banner.img.uri}
      />
      </a>
      <p class="titulo">{banner.text}</p>
    </div>
    
    {/* <div>
    <a href="https://alphafm.com.br/promocoes/concorra-a-ingressos-para-assistir-anastasia---o-musical-no-teatro-renault">
      <img
        alt="Concorra a ingressos para assistir Anastasia - O Musical no Teatro Renault"
        src="https://beta.alphafm.com.br/uploads/promocoes/ANASTASIA_ELETROMIDIA-CIRCUITO_baixa-1.jpg"
        
      />
    </a>
      <p class="titulo">Concorra a ingressos para assistir "Anastasia - O Musical” no Teatro Renault</p>
    </div>
    <div>
    <a href="https://alphafm.com.br/promocoes/concorra-a-ingressos-para-a-peca-tres-mulheres-altas-no-teatro-tuca">
      <img
        alt="Concorra a ingressos para a peça “Três Mulheres Altas”, no Teatro Tuca"
        src="https://beta.alphafm.com.br/uploads/promocoes/tres-mulheres.jpg"
        
      />
      </a>
      <p class="titulo">Concorra a ingressos para a peça “Três Mulheres Altas”, no Teatro Tuca</p>
    </div>
    <div>
    <a href="https://alphafm.com.br/promocoes/concorra-a-ingressos-para-o-show-de-bonnie-tyler-dia-12-de-novembro-no-tokio-marine-hall">
      <img
        alt="Concorra a ingressos para o show de Bonnie Tyler, dia 12 de novembro, no Tokio Marine Hall"
        src="https://beta.alphafm.com.br/uploads/promocoes/blogpost_674890_img1_bonnie-1.jpg"
        
      />
      </a>
      <p class="titulo">Concorra a ingressos para o show de Bonnie Tyler, dia 12 de novembro, no Tokio Marine Hall</p>
    </div>
    <div>
    <a href="https://alphafm.com.br/promocoes/concorra-a-ingressos-para-o-show-de-toquinho-e-demonios-da-garoa-dia-11-de-novembro-no-tokio-marine-hall">
      <img
        alt="Concorra a ingressos, para o show de Toquinho e Demônios da Garoa, dia 11 de novembro, no Tokio Marine Hall"
        src="https://beta.alphafm.com.br/uploads/promocoes/toquinho-demoniosdagaroa-eventim-artwork-1.jpg"
        
      />
      </a>
      <p class="titulo">Concorra a ingressos, para o show de Toquinho e Demônios da Garoa, dia 11 de novembro, no Tokio Marine Hall</p>
    </div> */}
    
  </InfiniteCarousel>
  );
}
