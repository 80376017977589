import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";
import Skeleton from "react-loading-skeleton";

import "./PublicationsColumnist.scss";

export default function PublicationsColumnist({ data = [], load = true }) {
  const [loadColumnist, setLoadColumnist] = useState(true);
  const [dataColumnist, setDataColumnist] = useState([]);

  useEffect(() => {
    setLoadColumnist(true);
    if (data.id_colunista) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  async function getData() {
    const response = await api.get(
      `posts-colunistas/categoria?paginacao=true&pagina=1&id_categorias_colunistas=${data.id_colunista}`,
    );
    setDataColumnist(response.data.data.data);
    setLoadColumnist(false);
  }

  return (
    <div className="publicationsColumnist">
      <div className="infos">
        {dataColumnist.map((row, key) => (
          <div key={key} className="publicationsPodasts backgroundPodcast dark">
            <h3>{loadColumnist === false ? row.titulo : <Skeleton width="100%" height="100%" />}</h3>
            {loadColumnist === false ?

              <div dangerouslySetInnerHTML={{ __html: row.audio }} />
              :
              <Skeleton width="100%" height="100%" />
            }

            <div className="publicationsPodcastDescription">
            {loadColumnist === false ?
              <div dangerouslySetInnerHTML={{ __html: row.texto }} />
              :
              <Skeleton width="100%" height="100%" />
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
