import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";
import Button from "../../Buttons/Button/Button";
import CardError from "../../../Components/Cards/CardError";
import CardImage from "../../../Components/Cards/CardImage";
import { Helmet } from "react-helmet";

import "./CategoryItems.scss";

export default function CategoryItems(props) {
  const [load, setLoad] = useState(true);
  const [plus, setPlus] = useState(10);
  const pagina = 1;
  const [idSubcategory, setIdSubcategory] = useState(props.props.match.path === '/noticias/culturaSP' ? 1019 :
                                                     props.props.match.path === '/noticias/pets' ? 1009 :
                                                     props.props.match.path === '/noticias/cultentr' ? 7 :
                                                     props.props.match.path === '/noticias/bem-estar' ? 1010 :
                                                     props.props.match.path === '/noticias/noticias' ? 13 :
                                                     props.props.match.path === '/noticias/coronavirus' ? 1003 : 7);
  const [data, setData] = useState([]);
  useEffect(() => {
    setLoad(true);
    api.get(`/posts/categoria?paginacao=true&itens=${plus}&pagina=${pagina}&categoria=${idSubcategory}`,).then((res) => {
        if (res.data && res.data.data && res.data.data.data) setData(res.data.data.data);
        setLoad(false);
      });
  }, [idSubcategory, plus]);

  function handleOnLoadMore() {
    setPlus(plus + 10)
  }

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content='https://alphafm.com.br/noticias' />
          <meta property="og:type" content='Noticias' />
          <meta property="og:title" content='Notícias Alpha FM' />
          <meta property="og:description" content='Notícias Alpha FM' />
          <meta property="og:image" content='https://scontent.fcgh17-1.fna.fbcdn.net/v/t39.30808-6/251985772_10158083644322155_2941830615724812929_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeGzf3iNk6jmchaL2pR9OWvOpfrLzFQUUF2l-svMVBRQXcP2WgFiA1NvETooFCEpbPmBwQo0c1GJtPQjmSsL_IsG&_nc_ohc=zErsoQe3eFsAX-8vmK8&_nc_ht=scontent.fcgh17-1.fna&oh=546bc000270c519bd7717ce6f120f8ae&oe=61B40840' />
          
          <title>Notícias Alpha FM</title>
          <link rel="canonical" href="https://alphafm.com.br/" />
      </Helmet>

      <div className="categorieButton">
        <div className="buttons">
          <Button
            onClick={() => setIdSubcategory(7)}
            className={`btn ${
              idSubcategory === 7 ? "primary" : "outline gray dark"
            }`}
          >
            Cultura &amp; entretenimento
          </Button>

          <Button
            onClick={() => setIdSubcategory(1010)}
            className={`btn ${
              idSubcategory === 1010
                ? "primary"
                : "outline gray buttonsDark dark"
            }`}
          >
            BEM-ESTAR
          </Button>

          <Button
            onClick={() => setIdSubcategory(1009)}
            className={`btn ${
              idSubcategory === 1009
                ? "primary"
                : "outline gray buttonsDark dark"
            }`}
          >
            Pets
          </Button>

          <Button
            onClick={() => setIdSubcategory(13)}
            className={`btn ${
              idSubcategory === 13 ? "primary" : "outline gray buttonsDark dark"
            }`}
          >
            Notícias
          </Button>

          <Button
            onClick={() => setIdSubcategory(1003)}
            className={`btn ${
              idSubcategory === 1003
                ? "primary"
                : "outline gray buttonsDark dark"
            }`}
          >
            Coronavírus
          </Button>

          <Button
            onClick={() => setIdSubcategory(1019)}
            className={`btn ${
              idSubcategory === 1019
                ? "primary"
                : "outline gray buttonsDark dark"
            }`}
          >
            Cultura SP
          </Button>
        </div>
      </div>
      <div className="categoryItems">
        {data.length <= 0 && load === false ? (
          <CardError
            isErrorCategory={true}
          />
        ) : (
          <>
            {data.map((row, key) => (
              <CardImage
                key={key}
                data={{
                  data: row.criado,
                  title: row.titulo,
                  link: "ler mais",
                  to: `/materia/${row.slug}`,
                  load: load,
                  image: row.imagem,
                  height: "280px"
                }}
              />
            ))}
          </>
        )}
        
      </div>

      <div className="containerLoadMore">
        {data.length > 0 && (
          <Button
            onClick={handleOnLoadMore}
            className={`btn primary`}
          >
            carregar mais notícias
          </Button>
        )}
      </div>
    </>
  );
}
