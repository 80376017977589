import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";

import IconShare from "../../Icons/IconShare";

import "./PlaylistInternalHeader.scss";

export default function PlaylistInternalHeader({ props, load, data = [] }) {
  const [share, setShare] = useState(false);
  const shareUrl = `https://alphafm.com.br/playlist/${data.id_playlist}`;

  useEffect(() => {
    return () => {
      window.location.reload();
    };
  }, [props]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          property="og:url"
          content={`https://alphafm.com.br/playlist/${data.slug}`}
        />
        <meta property="og:type" content="Promocao" />
        <meta property="og:title" content={data.nome} />
        <meta property="og:description" content={data.nome} />
        <meta property="og:image" content={data.imagem_interna} />

        <title>{data.nome}</title>
        <link rel="canonical" href="https://alphafm.com.br/playlist/" />
      </Helmet>

      <div className="playlistInternalHeader">
        <div className="subtitlePlaylist">
          {load === false ? (
            <>
              <p>{data.resumo || ""}</p>

              <div className="sectionShare">
                <div className={`buttonsSharelink ${share ? "isShare" : ""}`}>
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={32} round={true} />
                  </FacebookShareButton>

                  <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size={32} round={true} />
                  </WhatsappShareButton>

                  <LinkedinShareButton url={shareUrl}>
                    <LinkedinIcon size={32} round={true} />
                  </LinkedinShareButton>
                </div>
                <button
                  className="buttonShare"
                  onClick={() => setShare(!share)}
                >
                  <IconShare size={22} />
                </button>
              </div>
            </>
          ) : (
            <Skeleton width="100%" height="20px" />
          )}
        </div>

        <div className="playlistInternalMain">
          <iframe
            src={data.youtube_url}
            title={data.nome}
            allowtransparency="true"
            allow="encrypted-media"
          ></iframe>
        </div>
      </div>
    </>
  );
}
