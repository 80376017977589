import React, { useEffect } from "react";
import ColumnControl from "../../Components/ColumnControl/ColumnControl";
import RowControl from "../../Components/RowControl/RowControl";
import WidgetControl from "../../Components/WidgetControl/WidgetControl";
import ScheduleItems from "../../Components/Widgets/ScheduleItems/ScheduleItems";
import { init, sendView } from "../../GA/ga4";

export default function Schedule(props) {
  useEffect(() => {
    // init()
    sendView("/agenda")
  }, [])

  return (
    <div id="schedule" className="page">
      <RowControl>
        <ColumnControl column={12}>
          <WidgetControl title="Agenda">
            <ScheduleItems />
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
