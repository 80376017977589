import React from "react";
import { Link } from "react-router-dom";
import ImageAsync from "react-image-async";
import Skeleton from "react-loading-skeleton";
import Datetime from "../../../Helper/Datetime";

import ErrorBusca from "../../../Images/ErrorBusca.jpg";

import "./styles.scss";
import moment from "moment";

export default function CardImagePodcast({ data }) {
  const SRC = data.image.split('http').length === 2 ? data.image : data.upload + data.image

  return (
    <div id="containerCardPodcast">
      <Link to={data.to} className="cardImage cardImageDark">

        <ImageAsync src={SRC}>
          {({ error }) =>
            !error ? (
              <div className="imagess imageDark">
                {data.load === false ? (
                  <img src={`${SRC}`} alt="default" />
                ) : (
                  <Skeleton width="100%" height={data.height} />
                )}
              </div>
            ) : (
              <div className="imagess filterDark imageDark">
                {data.load === false ? (
                  <img src={`${ErrorBusca}`} alt="default" />
                ) : (
                  <Skeleton width="100%" height={data.height} />
                )}
              </div>
            )
          }
        </ImageAsync>

        {data.data && (
          <span className="cardDate">
            {data.load === false ? (
              `
              ${moment(data.data)
                .format('DD/MM/YYYY HH:mm')} ${" "} ${data.category ? data.category : ""
              } `
            ) : (
              <Skeleton height="100%" />
            )}
          </span>
        )}

        <h2 className={`cardTitle ${!data.data ? "notData" : ""}`}>
          {data.load === false ? data.title : <Skeleton height="100%" />}
        </h2>
        {data.load === false ? (
          <>
            {data.subtitle && (
              <p className="cardSubTitle" dangerouslySetInnerHTML={{ __html: data.subtitle }} />
            )}
          </>
        ) : (
          <p className="cardSubTitle">
            {[100, 75].map(item => (
              <Skeleton key={`sub_T${item}`} width={`${item}%`} />
            ))}
          </p>
        )}

        <span className="cardLink">
          {data.load === false ? data.link : <Skeleton height="100%" width='40%' />}
        </span>
      </Link>
    </div>
  );
}
