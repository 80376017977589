import React from "react";
import ColumnControl from "../../Components/ColumnControl/ColumnControl";
import RowControl from "../../Components/RowControl/RowControl";
import WidgetControl from "../../Components/WidgetControl/WidgetControl";
import SignUp from "../../Components/Login/SignUp";
import { useEffect } from "react";
import { init, sendView } from "../../GA/ga4";

export default function Registrations(props) {
  useEffect(() => {
    // init()
    sendView("/cadastro")
  }, [])


  return (
    <div id="registrations" className="page">
      <RowControl>
        <ColumnControl column={12}>
          <WidgetControl title="Cadastro">
            <SignUp />
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
