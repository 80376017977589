import React from 'react'
import ReactDOM from 'react-dom'
import Routes from './Routes'
import * as serviceWorker from './serviceWorker'

import './Styles/Global.scss'

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-MNSK88J'
}

TagManager.initialize(tagManagerArgs)


ReactDOM.render(
  <>
      <Routes />
  </>,
  document.getElementById('tropa')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
