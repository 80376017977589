import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { usePlay } from "../../Context/PlayContext";
import IconPause from "../Icons/IconPause";
import IconPlay from "../Icons/IconPlay";
import IconVolume from "../Icons/IconVolume";

import ImageDefault from '../../Images/Playlist1.png'

import "./PlayerAlphaMpb.scss";

export default function PlayerAlphaMpb() {
  const { isPlayingMpb, setIsPlayingMpb, setPlayingStateMpb } = usePlay();
  const audioRef = useRef(null);

  const [status, setStatus] = useState({
    tocando: [
      {
        song: "...",
        singer: "...",
      },
    ],
  });

  useEffect(() => {
    if (!audioRef.current) return;

    if (isPlayingMpb) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    getStatus();
  }, [isPlayingMpb]);

  async function getStatus() {
    const status = await Axios.get(
      "https://players.gc2.com.br/cron/alphafm/results.json?_=" +
        new Date().getTime(),
    );

    {
      status.data.musicas && setStatus({ ...status.data.musicas[0] });
    }
  }

  return (
    <div id="playerMpb" className={`${isPlayingMpb && 'openMpb'}`}>
      {isPlayingMpb === true ? (
        <div className="infos">
            <div className="top">
              <span>
                Ouça <b>ao vivo</b> <u>|</u> SP
              </span>
              <IconVolume />
            </div>

            <div className="music">
              <b>Agora:</b>{" "}
              <span>
                Tocando Alpha MPB
                {/* {status.tocando[0].song} - {status.tocando[0].singer} */}
              </span>
            </div>
          </div>
       ) : (
        <span>
          ALPHA MPB
        </span>
       )}

      <div
        className={`album`}
        style={{ backgroundImage: `url(${ImageDefault})` }}
      >
        {isPlayingMpb ? (
          <IconPause onClick={() => setIsPlayingMpb(!isPlayingMpb)} />
        ) : (
          <IconPlay onClick={() => setIsPlayingMpb(!isPlayingMpb)} />
        )}

        <audio
          src="https://playerservices.streamtheworld.com/api/livestream-redirect/ALPHAMPBAAC.aac?dist=site-alphampb"
          ref={audioRef}
          autoPlay
          onPlay={() => setPlayingStateMpb(true)}
          onPause={() => setPlayingStateMpb(false)}
        />
      </div>
    </div>
  );
}
