import ReactGA4 from 'react-ga4'

export function init() {
  ReactGA4.initialize([
    { 
      trackingId: 'G-CH3MTGJZ08',
    },
    { 
      trackingId: 'G-1HH2LDDS34',
    },
    { 
      trackingId: 'G-S9767L0R4Y',
    },
  ]);
}

export function sendView(route) {
  ReactGA4.send({ hitType: "pageview", page: route });
}
