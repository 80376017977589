import React from 'react'

export default function Container({page, children})
{

    return(
        <div className="container background dark">
            {children}
        </div>
    )

}