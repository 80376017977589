
import React, { useEffect } from 'react';
import ColumnControl from '../../Components/ColumnControl/ColumnControl';
import RowControl from '../../Components/RowControl/RowControl';
import WidgetControl from '../../Components/WidgetControl/WidgetControl';
import Competition from '../../Components/Widgets/Competition/Competition';
import PromotionResults from '../../Components/Widgets/PromotionResults/PromotionResults';
import { init, sendView } from '../../GA/ga4';

export default function Promotion(props) {
    useEffect(() => {
        // init()
        sendView("/promocoes")
    }, [])

    return (
        <div id="news" className="page">

            <RowControl>

                <ColumnControl column={8}>
                    <WidgetControl title="Concursos">
                        <Competition />
                    </WidgetControl>
                </ColumnControl>

                <ColumnControl column={4}>
                    <WidgetControl title="Resultados">
                        <PromotionResults />
                    </WidgetControl>
                </ColumnControl>

            </RowControl>

        </div>
    )

}