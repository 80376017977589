import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { api } from "../../../Api/app";
import CardImageDescription from "../../../Components/Cards/CardImageDescription";
import Button from "../../Buttons/Button/Button";

import "./Competition.scss";

export default function Competition() {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);
  const [plus, setPlus] = useState(10)

  useEffect(() => {
    setLoad(true);
    api.get(`promocoes?paginacao=true&itens=${plus}&pagina=1`).then((res) => {
      if (res.data && res.data.data && res.data.data.data) setData(res.data.data.data);
      setLoad(false);
    });
  }, [plus]);

  function handleOnLoadMore() {
    setPlus(plus + 10)
  }

  return (
    <>
      <div className="competition">

        <Helmet>
            <meta charSet="utf-8" />
            <meta property="og:url" content='https://alphafm.com.br/promocoes' />
            <meta property="og:type" content='Concurso' />
            <meta property="og:title" content='Concursos Alpha FM' />
            <meta property="og:description" content='Venha participar dos concursos da Alpha FM' />
            <meta property="og:image" content='https://scontent.fcgh17-1.fna.fbcdn.net/v/t39.30808-6/251985772_10158083644322155_2941830615724812929_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeGzf3iNk6jmchaL2pR9OWvOpfrLzFQUUF2l-svMVBRQXcP2WgFiA1NvETooFCEpbPmBwQo0c1GJtPQjmSsL_IsG&_nc_ohc=zErsoQe3eFsAX-8vmK8&_nc_ht=scontent.fcgh17-1.fna&oh=546bc000270c519bd7717ce6f120f8ae&oe=61B40840' />
            
            <title>Concursos</title>
            <link rel="canonical" href="https://alphafm.com.br/" />
        </Helmet>

        {data.map((row, key) => (
          <CardImageDescription
            key={key}
            data={{
              data: row.criado,
              title: row.promocao,
              // subtitle: row.slug,
              link: "ler mais",
              to: `/promocoes/${row.slug}`,
              load: load,
              image: row.imagem,
              height: "280px",
              src: "https://beta.alphafm.com.br/uploads/promocoes/"
            }}
          />
        ))}

      </div>
      <div className="buttonPLus">
        {data.length > 0 && (
            <Button
              onClick={handleOnLoadMore}
              className={`btn primary`}
            >
              carregar mais concursos
            </Button>
          )}
      </div>
    </>
  );
}
