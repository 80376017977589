import React from "react";
import ImageAsync from "react-image-async";
import Placeholder from "../../../Images/PlaceholderImage.jpg";
import Skeleton from "react-loading-skeleton";
import Datetime from "../../../Helper/Datetime";
import { Helmet } from "react-helmet";

import "./DetailedNews.scss";
import moment from "moment";

export default function DetailedNews({ load = true, data = {} }) {
  const SRC = `https://beta.alphafm.com.br/uploads/blog/`;

  return (
    <div className="detailed-news">
      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content={data.slug} />
          <meta property="og:type" content={data.category} />
          <meta property="og:title" content={data.titulo} />
          <meta property="og:description" content={data.resumo} />
          <meta property="og:image" content={SRC + data.imagem} />
          
          <title>{data.titulo}</title>
          <link rel="canonical" href="https://alphafm.com.br/materia/" />
      </Helmet>
      <div className="infos-head">
        <p>
          {load === false ? (
            data.resumo
          ) : (
            <Skeleton width="100%" height="100%" />
          )}
        </p>

        {load === false ? (
      
          <span>
            {moment(data.data_post)
             .format('DD/MM/YYYY HH:mm' )}{" "}
            {/* {Datetime.string(data.data_post)}{" "} */}
            {data.categoria && `| ${data.categoria}`}{" "}
            {data.autor && "| Por: " + data.autor}
          </span>
        ) : (
          <span>
            <Skeleton />
          </span>
        )}
      </div>

      {load === false ? (
        <>
          <ImageAsync src={SRC}>
            {({ loaded, error }) =>
              error ? (
                <div className="image">
                  <img src={`${SRC + data.imagem}` } alt="Uma boaimagem" />
                  <div
                    className="blur"
                    style={{ backgroundImage: `url(${SRC + data.imagem})` }}
                  ></div>
                </div>
              ) : (
                <div
                  className="image placeholder imageDark"
                  style={{ backgroundImage: `url(${Placeholder})` }}
                />
              )
            }
          </ImageAsync>
        </>
      ) : (
        <Skeleton width="100%" height="480px" />
      )}

      <div className="info">
        {load === false ? (
          <p dangerouslySetInnerHTML={{ __html: data.texto }}></p>
        ) : (
          <Skeleton width="100%" />
        )}
      </div>
    </div>
  );
}
