
import React, { useEffect, useState } from 'react';
import RowControl from '../../../Components/RowControl/RowControl';
import ColumnControl from '../../../Components/ColumnControl/ColumnControl';
import WidgetControl from '../../../Components/WidgetControl/WidgetControl';
import PromotionResults from '../../../Components/Widgets/PromotionResults/PromotionResults';
import { api } from '../../../Api/app';
import PromotionNews from '../../../Components/Widgets/PromotionNews/PromotionNews';
import { init, sendView } from '../../../GA/ga4';

export default function PromotionInternal(props) {

    const [load, setLoad] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        // init()
        sendView(`/promocoes/${props.match.params.slug}`)
    }, [props.match.params.slug])

    async function getData() {
        setLoad(true)
        const response = await api.get(`promocoes/slug/${props.match.params.slug}`)
        setData(response.data.data[0])
        setLoad(false)
    }

    return (
        <div id="promotionInternal" className="page">

            <RowControl>

                <ColumnControl column={8}>
                    <WidgetControl title={data.promocao} load={load}>
                        <PromotionNews load={load} data={data} />
                    </WidgetControl>
                </ColumnControl>

                <ColumnControl column={4}>
                    <WidgetControl title="Resultados">
                        <PromotionResults />
                    </WidgetControl>
                </ColumnControl>

            </RowControl>

        </div>
    )

}