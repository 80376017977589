import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { api } from "../../../Api/app";
// import CardImageDescription from "../../../CardImageDescription/index";
import CardImagePodcast from "../../../Components/Cards/CardImagePodcast";

import "./PodcastItems.scss";

export default function PodcastItems() {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoad(true);
    api.get("podcasts?paginacao=true&itens=100&pagina=1").then((res) => { 
      if (res.data && res.data.data.length) setData(res.data.data);
      setLoad(false);
    });
  }, []);

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content='https://www.alphafm.com.br/podcast' />
          <meta property="og:type" content='Podcasts' />
          <meta property="og:title" content='Podcasts' />
          <meta property="og:description" content='Venha conferir nossos Podcasts' />
          <meta property="og:image" content='https://scontent.fcgh17-1.fna.fbcdn.net/v/t39.30808-6/251985772_10158083644322155_2941830615724812929_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeGzf3iNk6jmchaL2pR9OWvOpfrLzFQUUF2l-svMVBRQXcP2WgFiA1NvETooFCEpbPmBwQo0c1GJtPQjmSsL_IsG&_nc_ohc=zErsoQe3eFsAX-8vmK8&_nc_ht=scontent.fcgh17-1.fna&oh=546bc000270c519bd7717ce6f120f8ae&oe=61B40840' />
          
          <title>Podcasts</title>
          <link rel="canonical" href="https://www.alphafm.com.br/podcast" />
      </Helmet>
    
      <div className="ContainerPodcast">
        <p>
          Ouça os podcasts da Alpha FM cheios de informação sobre os assuntos do
          momento pra você ficar por dentro de tudo.
        </p>
        <div className="podcastItems">
          {load ? [1, 2, 3].map(pod => (
            <CardImagePodcast
              key={`pod_loading${pod}`}
              data={{
                data: '',
                title: '',
                subtitle: '',
                link: "",
                to: undefined,
                load: true,
                image: '',
                height: "300px",
                podcast: ''
              }}
            />
          )) : (
            <>
              {data.map((row, key) => (
                <CardImagePodcast
                  key={key}
                  data={{
                    data: row.criado,
                    title: row.titulo,
                    subtitle: row.texto,
                    link: "ouvir agora",
                    to: `/podcast/${row.id_podcast}`,
                    load: load,
                    image: row.imagem,
                    height: "370px",
                    podcast: row.imagem
                  }}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}
