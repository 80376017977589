import React, { useEffect } from "react";
import ColumnControl from "../../Components/ColumnControl/ColumnControl";
import RowControl from "../../Components/RowControl/RowControl";
import WidgetControl from "../../Components/WidgetControl/WidgetControl";
import CategoryItems from "../../Components/Widgets/CategoryItems/CategoryItems";
import { init, sendView } from "../../GA/ga4";

export default function Noticias(props) {
  useEffect(() => {
    // init()
    sendView(props?.props?.match?.path)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.props?.match?.path])

  return (
    <div id="news" className="page">
      <RowControl>
        <ColumnControl column={12}>
          <WidgetControl title="Categorias">
            <CategoryItems props={props} />
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
