import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";
import Button from "../../Buttons/Button/Button";
import CardImage from "../../../Components/Cards/CardImage";
import CardError from "../../../Components/Cards/CardError";

import "./Destaques.scss";

export default function Destaques({ busca }) {
  const [load, setLoad] = useState(true);
  const pagina = 1;
  const [colunistas, setColunistas] = useState(13);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoad(true);
    api
      .get(
        `/posts/categoria?paginacao=true&itens=5&pagina=${pagina}&categoria=${colunistas}&busca=${busca}`,
      )
      .then((res) => {
        if (res.data && res.data.data && res.data.data.data) setData(res.data.data.data);
        setLoad(false);
      });
  }, [colunistas, busca]);

  return (
    <div className="destaques">
      <div className="buttons">
        <Button
          onClick={() => setColunistas(13)}
          className={`btn ${
            colunistas === 13 ? "primary" : "outline gray buttonsDark dark"
          }`}
        >
          Notícias
        </Button>

        <Button
          onClick={() => setColunistas(1010)}
          className={`btn ${
            colunistas === 1010 ? "primary" : "outline gray buttonsDark dark"
          }`}
        >
          Bem-estar
        </Button>
      </div>

      <div className="list">
        {(data.length <= 0) & (load === false) ? (
          <CardError />
        ) : (
          <>
            {data.map((row, key) => (
              <CardImage
                key={key}
                data={{
                  title: row.titulo,
                  link: "ler mais",
                  to: `/materia/${row.slug}`,
                  load: load,
                  image: row.imagem,
                  // height: '220px'
                }}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}
