
import React from 'react';
import ColumnControl from '../../Components/ColumnControl/ColumnControl';
import RowControl from '../../Components/RowControl/RowControl';
import WidgetControl from '../../Components/WidgetControl/WidgetControl';
import PlaylistItems from '../../Components/Widgets/PlaylistItems/PlaylistItems';
import { useEffect } from 'react';
import { init, sendView } from '../../GA/ga4';

export default function Playlist(props)
{
    useEffect(() => {
        // init()
        sendView("/playlist")
      }, [])

    return(
        <div id="playlist" className="page">

            <RowControl>
                <ColumnControl column={12}>
                    <WidgetControl title="Playlists">
                        <PlaylistItems />
                    </WidgetControl>
                </ColumnControl>
            </RowControl>

        </div>
    )

}