import React, { useState } from "react";
import { Link } from "react-router-dom";
import Dailymotion from "react-dailymotion";
import { usePlay } from "../../../Context/PlayContext";

import IconComentario from "../../Icons/IconComentario";
import IconCurtir from "../../Icons/IconCurtir";

import "./AoVivo.scss";

export default function AoVivo() {
  const load = true;
  const data = [{ video: "/embed/" }];
  const { isPlayingDayling, setPlayingStateDayling } = usePlay();

  return (
    <div className="ao-vivo">
      <Link
        to={load === false ? `${data[1].video}` : "/"}
        target="_blank"
        className="destaque"
      >
        <div className="image">
          <Dailymotion
            video="x82yivf"
            uiTheme="light"
            autoplay={true}
            paused={!isPlayingDayling}
            onPause={() => setPlayingStateDayling(false)}
            onPlay={() => setPlayingStateDayling(true)}
            mute={true}
          />
        </div>

        <div className="feedback" style={{ display: "none" }}>
          <button>
            <IconComentario />
            <span>915 comentários</span>
          </button>

          <button>
            <IconCurtir />
            <span>2,7k curtidas</span>
          </button>
        </div>
      </Link>
    </div>
  );
}
