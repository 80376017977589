
import React from 'react'

import "./RowControl.scss"

export default function RowControl({
    children
})
{

    return(
        <div className={`row`}>
            {children}
        </div>
    )

}