import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Datetime from "../../../Helper/Datetime";
import ModalDefault from "../../ModalDefault/ModalDefault";
import CloseMenu from "../../Icons/CloseMenu";
import { useAuth } from "../../../Context/AuthContext";
import SignIn from "../../../Components/Login/SignIn/SignIn";
import { Helmet } from "react-helmet";

import SucessImg from '../../../Images/sucess.png'

import "./PromotionNews.scss";
import { api } from "../../../Api/app";
import moment from "moment";

export default function PromotionNews({ load = true, data = [] }) {
  const { user } = useAuth();
  const [modalPromo, setModalPromo] = useState(false);
  const [modalSignIn, setModalSignIn] = useState(false);
  const [modal, setModal] = useState(false);
  const [question, setQuestion] = useState()

  async function handleOnPromo(id) {
    try {
      const data = {
        id_promocao: String(id),
        participacao: question,
      }
      const response = await api.post(`promocoes/participar`, data)
      console.log("data", response)
      setModalPromo(!modalPromo)
      
      setModal(!modal)
      setTimeout(() => {
        setModal(false)
      }, 2000)
    } catch (e) {
      console.log(e)
    }
  }

  console.log(data.data_inicio)

  return (
    <div className="PromotionNews-news">
      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content={`https://alphafm.com.br/promocoes/${data.slug}`} />
          <meta property="og:type" content='Promocao' />
          <meta property="og:title" content='Promocao' />
          <meta property="og:description" content={data.promocao} />
          <meta property="og:image" content={`https://beta.alphafm.com.br/uploads/promocoes/${data.imagem}`} />
          
          <title>Promocao</title>
          <link rel="canonical" href="https://alphafm.com.br/promocoes" />
      </Helmet>

      <div className="PromotionNews-head">

        {load === false ? (
          <span>
            {/* {Datetime.string(data.data_inicio)} */}
            {moment(data.data_inicio)
                .format('DD/MM/YYYY HH:mm' )}
            
            {" | "}
            {`Participe até ${data.data_fim}`}
          </span>
        ) : (
          <span>
            <Skeleton />
          </span>
        )}
      </div>

      <div className="image">
        {load === false ? (
          <>
            <img
              className="wallpaper"
              src={`https://beta.alphafm.com.br/uploads/promocoes/${data.imagem}`}
              alt={data.titulo}
            />
            {data.resultado === 'sim' ? '' : (

              <div className="rowQuestion">
                {data.slug == "concurso-dia-dos-pais-1" ?
                  (
                    <button className="buttonPromotion" onClick={() => window.location.href = "https://alphafm.com.br/diadospais/"}>
                      Acesse a página do concurso para participar
                    </button>
                  ) :
                  user ? 
                    (
                      <button className="buttonPromotion" onClick={() => setModalPromo(!modalPromo)}>
                        Participar
                      </button>
                    ) : 
                    (
                      <button className="buttonPromotion" onClick={() => setModalSignIn(!modalSignIn)}>
                        Logar para participar desse concurso
                      </button>
                    )
                }
              </div>
            )}
          </>
        ) : (
          <Skeleton className="wallpaper" width="100%" height="300px" />
        )}
        <div className={`info ${data.resultado === 'sim' && 'result'}`}>
          {load === false ? (
            <p dangerouslySetInnerHTML={{ __html: data.resultado === 'sim' ? data.resultado_texto : data.texto }}></p>
          ) : (
            <Skeleton />
          )}
        </div>
      </div>

      {modalPromo && (
        <ModalDefault
          id="overlayModalDefault"
          onClose={() => setModalPromo(!modalPromo)}
        >
          <div className="containerModalPromo">
            <div className="titleModalPromo">
              <h2>Concurso</h2>
              <h3>{data.promocao}</h3>
            </div>

            <div className="subtitleModalPromo">
              <div dangerouslySetInnerHTML={{ __html: data.pergunta }}></div>

              <textarea 
                rows="4" 
                cols="50" 
                placeholder="Digite uma texto"
                onChange={(event) => setQuestion(event.target.value )}
                />
            </div>

            <div className="footerModalPromo">
              <button
                className="buttonModalPromo"
                onClick={() => handleOnPromo(data.id_promocao)}
              >
                Participar
              </button>
            </div>

            <button
              className="closeModalPromo"
              onClick={() => setModalPromo(!modalPromo)}
            >
              <CloseMenu size={32} color={"#fff"} />
            </button>
          </div>
        </ModalDefault>
      )}

      {modalSignIn && <SignIn onClose={() => setModalSignIn(!modalSignIn)} />}

      {modal && (
        <ModalDefault id="overlayModalDefault" onClose={() => setModal(!modal)}>
          <div className="cardModal">
            <div className="cardContent">
              <div className="infoModal">
                <img src={SucessImg} alt="cancelar" />
                <h1 className="errorModal">Sucesso!</h1>
                <span className="messageModal">Participação realizada com sucesso!</span>
              </div>
              <div className="buttonModal">
                <button
                  className="buttonCancel"
                  onClick={() => setModal(!modal)}
                >
                  Obrigado por participar
                </button>
              </div>
            </div>
          </div>
        </ModalDefault>
      )}
    </div>
  );
}
