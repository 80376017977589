import React, { useState } from 'react'
import Skeleton from "react-loading-skeleton"
import "./WidgetControl.scss"

export default function WidgetControl({
    id,
    title,
    children,
    icon,
    configs = {},
    column = 1,
    editable = false,
    onChangeColumn = () => {},
    load = false
})
{

    const [settingsOpened, setSettingsOpened] = useState(false)

    document.addEventListener('click', function (event) {

        if (!event.target.closest(`#${id} .edit`)) setSettingsOpened(false)
    
    }, false)

    return(

        <div className={`widget`} id={id}>

            <div className="title">
                <div>
                    <h2 className="color dark">{ load === false ? title : <Skeleton width="100%" height="27px" />}</h2>
                    {icon}
                </div>

                { editable === true &&
                    <div className={`settings ${settingsOpened ? 'active' : 'inactive'}`}>

                        <button onClick={() => setSettingsOpened(!settingsOpened)} className="edit editColumnsDark">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 31.87 32"><g fill="#626262"><path d="M.646 5.646c-.847.848-.666 2.042.008 2.715c.027.026 2.734 2.612 6.541 6.419a.498.498 0 0 0 .708 0a.5.5 0 0 0 0-.707c-.574-.574-1.12-1.117-1.638-1.631l1.589-1.589a.5.5 0 0 0-.707-.707l-1.593 1.593a498.15 498.15 0 0 0-2.315-2.271l3.615-3.615a.5.5 0 0 0-.707-.707L2.521 8.772c-.736-.713-1.16-1.118-1.167-1.125c-.211-.211-.488-.805 0-1.293l5-5c.193-.196.707-.585 1.271-.024c.025.028 2.55 2.896 6.404 6.75a.5.5 0 0 0 .707-.707C10.906 3.544 8.4.698 8.354.646c-.932-.93-2.056-.651-2.707 0l-5.001 5z"/><path d="M21.854 24.146a.5.5 0 0 0-.707 0l-1.591 1.59a350.22 350.22 0 0 0-1.53-1.541a.5.5 0 0 0-.707.707a295.59 295.59 0 0 1 6.328 6.509c.343.344.813.533 1.323.533c.525 0 1.056-.205 1.385-.535l4.998-5.057c.629-.629.665-1.696.078-2.285l-6.706-6.705a.5.5 0 0 0-.707.707l6.706 6.705c.2.2.162.632-.079.873l-4.999 5.057c-.115.116-.376.24-.676.24c-.172 0-.418-.042-.604-.229c-.01-.011-.429-.458-1.156-1.219l3.644-3.644a.5.5 0 0 0-.707-.707l-3.629 3.629a328.619 328.619 0 0 0-2.26-2.326l1.596-1.596a.5.5 0 0 0 0-.706z"/><path d="M.142 31.829a.5.5 0 0 0 .501.129l10-2.979a.49.49 0 0 0 .212-.127l17.09-17.205c.024-.024.036-.055.054-.082l3.656-3.656a1.552 1.552 0 0 0 0-2.203L26.42.472c-.588-.588-1.615-.588-2.203 0l-3.739 3.739c-.024.024-.037.055-.055.083L3.307 21.411a.495.495 0 0 0-.122.199l-3.16 9.715a.499.499 0 0 0 .117.504zM20.86 5.271l1.039 1.039L6.376 21.875l-1.727-.392L20.86 5.271zM7 22.666L22.607 7.018l2.436 2.436L9.295 25H7v-2.334zm19.884-11.37L10.64 27.65l-.577-2.002L25.75 10.161l1.134 1.135zM3.993 22.359L6 22.815V25.5a.5.5 0 0 0 .5.5h2.624l.634 2.2l-8.488 2.529l2.723-8.37zm20.932-21.18a.57.57 0 0 1 .789 0l5.235 5.235a.554.554 0 0 1-.001.789l-3.371 3.372l-6.023-6.023l3.371-3.373z"/></g><rect x="0" y="0" width="31.87" height="32" fill="rgba(0, 0, 0, 0)" /></svg>
                        </button>

                        <div className="settings-menu">

                            <button onClick={() => onChangeColumn(2)} className={`${column === 2 ? 'active' : 'inactive'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><rect x="2" y="2" width="20" height="20" rx="2" fill="#626262"/></g><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>
                            </button>

                            <button onClick={() => onChangeColumn(3)} className={`${column === 3 ? 'active' : 'inactive'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><rect x="2" y="2" width="20" height="20" rx="2" fill="#626262"/></g><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><rect x="2" y="2" width="20" height="20" rx="2" fill="#626262"/></g><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>
                            </button>

                            <button onClick={() => onChangeColumn(4)} className={`${column === 4 ? 'active' : 'inactive'}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><rect x="2" y="2" width="20" height="20" rx="2" fill="#626262"/></g><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><rect x="2" y="2" width="20" height="20" rx="2" fill="#626262"/></g><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>
                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><rect x="2" y="2" width="20" height="20" rx="2" fill="#626262"/></g><rect x="0" y="0" width="24" height="24" fill="rgba(0, 0, 0, 0)" /></svg>
                            </button>
                            

                        </div>

                    </div>
                }
            </div>

            <div className={`content c-${column}`}>
                {children}
            </div>

        </div>

    )

}