const axios = require('axios');
const https = require('https');

var baseUrl

if(process.env.NODE_ENV === 'development') {
  baseUrl = 'https://backendnovo.alphafm.com.br/';
  // baseUrl = 'https://192.168.15.7:3333/';
  // baseUrl = 'http://localhost:3333/';
} else if (process.env.NODE_ENV === 'test') {
  baseUrl = 'https://backendnovo.alphafm.com.br/';
  // baseUrl = 'http://localhost:3333/';
} else {
  baseUrl = "https://backendnovo.alphafm.com.br/";
  // baseUrl = 'http://localhost:3333/';
}

const TOKEN = window.localStorage.getItem('@Alpha:token')

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Authorization': 'Bearer '+TOKEN,
    'Access-Control-Allow-Origin': 'https://www.alphafm.com.br/',
    'Access-Control-Allow-Credentials': 'true'
  },
  httpsAgent: new https.Agent({  rejectUnauthorized: false})
})

export { api, TOKEN }