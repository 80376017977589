import React, { useEffect } from "react";
import ColumnControl from "../../Components/ColumnControl/ColumnControl";
import RowControl from "../../Components/RowControl/RowControl";
import WidgetControl from "../../Components/WidgetControl/WidgetControl";
import ColumnistsItems from "../../Components/Widgets/ColumnistsItems/ColumnistsItems";
import PlayerAlpha from "../../Components/PlayerAlpha/PlayerAlpha";
import PlayerAlphaMpb from "../../Components/PlayerAlphaMpb/PlayerAlphaMpb";
import { init, sendView } from "../../GA/ga4";

export default function Player(props) {
  useEffect(() => {
    // init()
    sendView("/aovivo")
  }, [])

  return (
    <div id="player" className="page">
      <RowControl>
        <ColumnControl column={12}>
          <WidgetControl title="Player">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <PlayerAlpha isPagePlayer={true} />
              {/* <hr style={{ height: '1px', border: 0, background: '#8a8a8a' }} /> */}
              {/* <PlayerAlphaMpb isPagePlayer={true} /> */}
            </div>
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
