import React from "react";

import "./FilterSchedule.scss";

export default function FilterSchedule() {
  return (
    <div></div>
    // <div className="filterSchedule">
    //   <div className="buttons">
    //     <button className="btn outline gray">Cinema</button>

    //     <button className="btn outline gray buttonsDark dark">Exposição</button>

    //     <button className="btn outline gray buttonsDark dark">Lives</button>

    //     <button className="btn outline gray buttonsDark dark">Shows</button>

    //     <button className="btn outline gray buttonsDark dark">Teatro</button>

    //     <button className="btn primary buttonsDark dark">Todos</button>
    //   </div>

    //   <div className="announcedFilter">
    //     <AnnouncedFilter />
    //   </div>
    // </div>
  );
}
