import React, { useEffect, useState } from "react";
import { api } from "../../../Api/app";

import CardImageDescription from "../../../Components/Cards/CardImageDescription";
import "./ScheduleItems.scss";
import Button from "../../Buttons/Button/Button";
import { Helmet } from "react-helmet";

export default function ScheduleItems() {
  const [load, setLoad] = useState(true);
  const [idCategoria, setIdCategoria] = useState(6);
  const [data, setData] = useState([]);
  const [plus, setPlus] = useState(10);

  useEffect(() => {
    setLoad(true);
    api.get(`posts/categoria?paginacao=true&itens=${plus}&pagina=1&categoria=${idCategoria}`).then((res) => {
      if (res.data && res.data.data && res.data.data.data) setData(res.data.data.data);
      setLoad(false);
    });
  }, [idCategoria, plus]);

  function handleOnLoadMore() {
    setPlus(plus + 10)
  }

  return (
    <>

      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content='https://www.alphafm.com.br/agenda' />
          <meta property="og:type" content='Agenda' />
          <meta property="og:title" content='Agenddas Alpha FM' />
          <meta property="og:description" content='Venha conferir à Agendda da Alpha FM' />
          <meta property="og:image" content='https://scontent.fcgh17-1.fna.fbcdn.net/v/t39.30808-6/251985772_10158083644322155_2941830615724812929_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeGzf3iNk6jmchaL2pR9OWvOpfrLzFQUUF2l-svMVBRQXcP2WgFiA1NvETooFCEpbPmBwQo0c1GJtPQjmSsL_IsG&_nc_ohc=zErsoQe3eFsAX-8vmK8&_nc_ht=scontent.fcgh17-1.fna&oh=546bc000270c519bd7717ce6f120f8ae&oe=61B40840' />
          
          <title>Agenda</title>
          <link rel="canonical" href="https://www.alphafm.com.br/agenda" />
      </Helmet>

      <div className="filterSchedule">
        <div className="buttons">
          <Button
            onClick={() => setIdCategoria(6)}
            className={`btn ${idCategoria === 6 
              ? "primary" 
              : "outline gray dark"
            }`}
          >
            Cinema
          </Button>

          <Button
            onClick={() => setIdCategoria(1012)}
            className={`btn ${idCategoria === 1012 
              ? "primary" 
              : "outline gray dark"
            }`}
          >
            Exposição
          </Button>

          <Button
            onClick={() => setIdCategoria(1000)}
            className={`btn ${idCategoria === 1000 
              ? "primary" 
              : "outline gray dark"
            }`}
          >
            Shows
          </Button> 

          <Button
            onClick={() => setIdCategoria(22)}
            className={`btn ${idCategoria === 22 
              ? "primary" 
              : "outline gray dark"
            }`}
          >
            Teatro
          </Button> 
          
          <Button
            onClick={() => setIdCategoria(1005)}
            className={`btn ${idCategoria === 1005 
              ? "primary" 
              : "outline gray dark"
            }`}
          >
            Todos
          </Button>           
        </div>

      </div>
      <div className="scheduleItems">
        {data.map((row, key) => (

          <CardImageDescription
            key={key}
            data={{
              data: row.criado,
              category: row.categoria,
              title: row.titulo,
              subtitle: row.resumo,
              link: "ler mais",
              to: `/agenda/${row.slug}`,
              load: load,
              image: row.imagem,
              height: '298px'
            }}
          />
        ))}
      </div>

      <div className="containerLoadMore">
        {data.length > 0 && (
          <Button
            onClick={handleOnLoadMore}
            className={`btn primary`}
          >
            carregar mais notícias
          </Button>
        )}
      </div>
    </>
  );
}
