import React, { useCallback, useEffect, useRef, useState } from "react";
import { useField } from "@unform/core";
import { maskCEP, maskCPF, maskDate, maskPhone } from './masks';

import "./styles.scss";

const InputDefault = ({ name, label, mask, ...rest }) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isField, setIsField] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsField(!!inputRef.current?.value);
  }, []);

  const handleKeyUp = useCallback((e) => {
    if(mask === 'cpf') {
      maskCPF(e)
    } else if (mask === 'cep') {
      maskCEP(e)
    } else if (mask === 'date') {
      maskDate(e)
    } else if (mask === 'fone') {
      maskPhone(e)
    } else {
      return
    }
    
    return;
  }, [mask])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      <div
        className={`
        containerInput containerInputDark
        ${isFocused ? "isFocused" : ""}
        ${isField ? "isField" : ""}
        ${!!error ? "isError" : ""}
        `}
      >
        <label htmlFor={name}>{label}</label>

        <input
          id={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          onKeyUp={handleKeyUp}
          {...rest}
        />

        {error && (
          <div className="error animate__fadeInUp">
            <span>{error}</span>
          </div>
        )}
      </div>
    </>
  );
};

export default InputDefault;
