import React from 'react'
import IconPlay from '../Icons/IconPlay'

import "./OverlayPlay.scss"

export default function OverlayPlay({size, onClick})
{

    return(
        <div className="overlay-play">
            <button onClick={onClick}>
                <IconPlay size={size}/>
            </button>
        </div>
    )

}