
import React, { useEffect } from 'react';
import ColumnControl from '../../../Components/ColumnControl/ColumnControl';
import RowControl from '../../../Components/RowControl/RowControl';
import WidgetControl from '../../../Components/WidgetControl/WidgetControl';
import FilterSchedule from '../../../Components/Widgets/FilterSchedule/FilterSchedule';
import Notfound from '../../../Components/Widgets/NotFound/NotFound'
import { init, sendView } from '../../../GA/ga4';

export default function ScheduleNotfound(props)
{
    useEffect(() => {
        // init()
        sendView(`/agenda/${props.match.params.id}`)
      }, [props.match.params.id])

    return(
        <div id="scheduleNotfound" className="page">

            <RowControl>

                <ColumnControl column={12}>
                    <WidgetControl title="Agenda">
                        <RowControl row={1}>
                            <FilterSchedule />
                        </RowControl>

                            <Notfound />

                    </WidgetControl>
                </ColumnControl>
            </RowControl>

        </div>
    )

}