import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { api } from "../../../Api/app";
import ColumnControl from "../../../Components/ColumnControl/ColumnControl";
import RowControl from "../../../Components/RowControl/RowControl";
import WidgetControl from "../../../Components/WidgetControl/WidgetControl";
import PlaylistInternalHeader from "../../../Components/Widgets/PlaylistInternalHeader/PlaylistInternalHeader";
import { init, sendView } from "../../../GA/ga4";

export default function PlaylistInternal(props) {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([{}]);

  useEffect(() => {
    setLoad(true);
    api.get(`playlists/${props.match.params.id}`).then((res) => {
      setData(res.data.data[0]);
      setLoad(false);
    });
  }, [props.match.params.id]);

  useEffect(() => {
    // init()
    sendView(`/playlist/${props.match.params.id}`)
  }, [props.match.params.id])

  return (
    <div id="playlistInternal" className="page">
      <RowControl>
        <ColumnControl column={12}>
        <Link style={{marginBottom: '10px', display: 'block'}} to="/playlist">Voltar para playlists</Link>

          <WidgetControl title={data.nome} load={load}>
            <PlaylistInternalHeader {...props} load={load} data={data} />
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
