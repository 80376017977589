/* eslint-disable react-hooks/exhaustive-deps */
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { usePlay } from "../../Context/PlayContext";

import IconPause from "../Icons/IconPause";
import IconPlay from "../Icons/IconPlay";
import IconVolume from "../Icons/IconVolume";

import "./PlayerAlpha.scss";
import ErrorBusca from "../../Images/ErrorBusca.jpg";

export default function PlayerAlpha({ isPagePlayer }) {
  const className = isPagePlayer ? 'page-player-alpha' : 'player-alpha';
  const windowWidth = useRef(window.innerWidth);

  const { isPlaying, setIsPlaying, setPlayingState, isPlayingMpb, isPlayingClassics } = usePlay();
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(100);
  const [status, setStatus] = useState({
    tocando: [
      {
        song: "...",
        singer: "...",
      },
    ],
  });
  const [nowplayingAndLast4, setNowplayingAndLast4] = useState([])

  useEffect(() => {
    if (!audioRef.current) return;

    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }

    if (audioRef) {
      audioRef.current.volume = volume / 100;
    }
  }, [isPlaying, volume]);

  useEffect(() => {
    exec()
  }, [nowplayingAndLast4]);

  async function exec() {
    // console.log("_______________________________________________________________________________________________________")
    await delay(1000);
    getStatus();
  }

  function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function getStatus() {
    const status = await Axios.get(`https://players.gc2.com.br/cron/alphafm/results.json?_=${new Date().getTime()}`);

    if (Array.isArray(status.data.musicas) && status.data.musicas?.length > 0) {
      if (Array.isArray(status.data.musicas[0])) {
        setStatus(prev => ({ ...prev, tocando: status.data.musicas[0].slice() }));
      } else if (Array.isArray(status.data.musicas[0]?.tocando)) {
        setStatus(prev => ({ ...prev, tocando: status.data.musicas[0].tocando.slice() }));
      } else {
        setStatus(prev => ({ ...prev, tocando: prev.tocando.slice() }));
      }

      if (!Array.isArray(status.data.musicas[0]?.jatocou[0].musicas)) {
        return;
      }

      // const API_KEY_LASTFM = "79e3ca7196916844382e2e30e4d31ed2"
      // const API_KEY_LASTFM = "1d07ae6d0a77914229c0ccaa75867fdf"
      const API_KEY_LASTFM = "8fe35c463033f75f83d2b81828e06e3f"

      let copyOfNowplayingAndLast4 = new Map(nowplayingAndLast4);

      const i = status.data.musicas[0]?.jatocou[0].musicas.forEach(async music => {
        const obj = { song: music.song[0], singer: music.singer[0] }

        if (!copyOfNowplayingAndLast4.has(JSON.stringify(obj))) {
          const keyMusic = `artist=${obj.singer}&track=${obj.song}`
          const nowPlayingInfo = localStorage.getItem(keyMusic)
          if(!nowPlayingInfo) {
            const artistData = await Axios.get(
              `https://ws.audioscrobbler.com/2.0/?method=track.getInfo&api_key=${API_KEY_LASTFM}&${keyMusic}&format=json`
            )
  
            if (!(artistData.data?.message === "Track not found") && Array.isArray(artistData.data.track?.album?.image)) {
              obj.image = artistData.data.track.album.image[2]["#text"] ? artistData.data.track.album.image[2]["#text"] : artistData.data.track.album.image[2]
            } else {
              obj.image = ErrorBusca
            }
  
            const musicData = JSON.stringify({ song: obj.song, singer: obj.singer })

            localStorage.setItem(keyMusic, musicData)
            copyOfNowplayingAndLast4.set(musicData, obj)
          } else {
            copyOfNowplayingAndLast4.set(nowPlayingInfo)
          }
        }
      })

      if (copyOfNowplayingAndLast4.size > 4) {
        copyOfNowplayingAndLast4 = new Map([...copyOfNowplayingAndLast4].slice(0, 4))
      }

      setNowplayingAndLast4(copyOfNowplayingAndLast4)
    }
  }

  const nowplayingAndLast4DisplayFormat = [...nowplayingAndLast4].map(music => music[1]).reverse()

  return isPagePlayer ?
    (
      <div id={className} className="openAlpha">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <div className="infos">
            <div className="top">
              <span>
                Ouça <b>ao vivo</b> <u>|</u> SP
              </span>
              {/* <IconVolume /> */}
            </div>

            <div className="music">
              <b>Agora:</b>{" "}
              <span>
                {status.tocando[0].song} - {status.tocando[0].singer}
              </span>
            </div>
          </div>

          <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", flexDirection: "row", flexWrap: "wrap", gap: "1rem", paddingTop: "2rem", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
              <div className={`album`} style={{ backgroundImage: `url(${status.tocando[0].background})`, display: "flex", justifyContent: "space-around" }}>
                {isPlaying ?
                  (
                    <IconPause onClick={() => setIsPlaying(!isPlaying)} />
                  ) : (
                    <IconPlay onClick={() => setIsPlaying(!isPlaying)} />
                  )
                }

                <audio
                  id="audio"
                  src="https://playerservices.streamtheworld.com/api/livestream-redirect/RADIO_ALPHAFM_ADP.aac?dist=site-alphafm"
                  ref={audioRef}
                  autoPlay
                  volume={volume}
                  onPlay={() => setPlayingState(true)}
                  onPause={() => setPlayingState(false)}
                />
              </div>

              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem", }}>
                <svg role="presentation" fill="rgba(32, 58, 194)" height="16" width="16" aria-hidden="true" aria-label="Volume high" id="volume-icon" viewBox="0 0 16 16" data-encore-id="icon" className="Svg-sc-ytk21e-0 haNxPq">
                  <path d="M9.741.85a.75.75 0 0 1 .375.65v13a.75.75 0 0 1-1.125.65l-6.925-4a3.642 3.642 0 0 1-1.33-4.967 3.639 3.639 0 0 1 1.33-1.332l6.925-4a.75.75 0 0 1 .75 0zm-6.924 5.3a2.139 2.139 0 0 0 0 3.7l5.8 3.35V2.8l-5.8 3.35zm8.683 4.29V5.56a2.75 2.75 0 0 1 0 4.88z" />
                  <path d="M11.5 13.614a5.752 5.752 0 0 0 0-11.228v1.55a4.252 4.252 0 0 1 0 8.127v1.55z" />
                </svg>

                <input
                  type="range"
                  value={volume}
                  onChange={(event) => {
                    setVolume(Number(event.target.value));
                  }}
                />
              </div>
            </div>

            <div>
              <div className="letra" dangerouslySetInnerHTML={{
                __html: `
                <div style='display: flex; text-align: center;'>
                  ${status.tocando[0].letra + "</br>" ?? 'Carregando...'}
                </div>
              `}}
              />
            </div>
          </div>
        </div>

        <div style={{ width: "100%", marginTop: "2rem" }}>
          <h3 style={{ fontSize: "24px" }}>Tocadas anteriormente</h3>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-evenly", width: "100%", flexWrap: "wrap" }}>
            {
              nowplayingAndLast4DisplayFormat.map(music => (
                <div key={(Math.random() * 100) * 100} style={{ width: 186, background: "rgba(32, 58, 194, 0.05)", borderRadius: "0.5rem", overflow: "hidden", marginTop: "1rem" }}>
                  <img src={music.image} alt={music.singer} width={186} />
                  <div style={{ padding: "1rem" }}>
                    <h3 style={{ fontSize: "16px" }}>{music.song}</h3>
                    <h4 style={{ fontSize: "12px" }}>{music.singer}</h4>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    ) :
    windowWidth.current < 800 ?
      (
        <div id={className} className={`openAlpha`}>
          <div className="infos">
            <div className="top">
              <span>
                Ouça <b>ao vivo</b> <u>|</u> SP
              </span>
              <IconVolume />
            </div>

            <div className="music">
              <b>Agora:</b>{" "}
              <span>
                {status.tocando[0].song} - {status.tocando[0].singer}
              </span>
            </div>
          </div>

          <div
            className={`album`}
            style={{ backgroundImage: `url(${status.tocando[0].cover})` }}
          >
            {isPlaying ? (
              <IconPause onClick={() => setIsPlaying(!isPlaying)} />
            ) : (
              <IconPlay onClick={() => setIsPlaying(!isPlaying)} />
            )}

            <audio
              src="https://playerservices.streamtheworld.com/api/livestream-redirect/RADIO_ALPHAFM_ADP.aac?dist=site-alphafm"
              ref={audioRef}
              autoPlay
              onPlay={() => setPlayingState(true)}
              onPause={() => setPlayingState(false)}
            />
          </div>
        </div>
      )
      :
      (
        <div id={className} className={`${isPlayingMpb === false && isPlayingClassics === false && "openAlpha"}`}>
          {isPlayingMpb === false && isPlayingClassics === false ? (
            <div className="infos">
              <div className="top">
                <span>
                  Ouça <b>ao vivo</b> <u>|</u> SP
                </span>
                <IconVolume />
              </div>

              <div className="music">
                <b>Agora:</b>{" "}
                <span>
                  {status.tocando[0].song} - {status.tocando[0].singer}
                </span>
              </div>
            </div>
          ) : (
            <span>ALPHA SP</span>
          )}

          <div
            className={`album`}
            style={{ backgroundImage: `url(${status.tocando[0].cover})` }}
          >
            {isPlaying ? (
              <IconPause onClick={() => setIsPlaying(!isPlaying)} />
            ) : (
              <IconPlay onClick={() => setIsPlaying(!isPlaying)} />
            )}

            <audio
              src="https://playerservices.streamtheworld.com/api/livestream-redirect/RADIO_ALPHAFM_ADP.aac?dist=site-alphafm"
              ref={audioRef}
              autoPlay
              onPlay={() => setPlayingState(true)}
              onPause={() => setPlayingState(false)}
            />
          </div>
        </div>
      );
}
