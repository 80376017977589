import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { api } from "../../../Api/app";
import CardImagePlaylist from "../../../Components/Cards/CardImagePlaylist";

import "./PlaylistItems.scss";

export default function PlaylistItems() {
  const [load, setLoad] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoad(true);
    api.get(`playlists`).then((res) => {
      setData(res.data.data);
      setLoad(false);
    });
  }, []);

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:url" content='https://www.alphafm.com.br/playlist' />
          <meta property="og:type" content='Musicas' />
          <meta property="og:title" content='Musicas Alpha FM' />
          <meta property="og:description" content='Venha conferir nossas musicas Alpha FM' />
          <meta property="og:image" content='https://scontent.fcgh17-1.fna.fbcdn.net/v/t39.30808-6/251985772_10158083644322155_2941830615724812929_n.jpg?_nc_cat=104&ccb=1-5&_nc_sid=09cbfe&_nc_eui2=AeGzf3iNk6jmchaL2pR9OWvOpfrLzFQUUF2l-svMVBRQXcP2WgFiA1NvETooFCEpbPmBwQo0c1GJtPQjmSsL_IsG&_nc_ohc=zErsoQe3eFsAX-8vmK8&_nc_ht=scontent.fcgh17-1.fna&oh=546bc000270c519bd7717ce6f120f8ae&oe=61B40840' />
          
          <title>Playlist</title>
          <link rel="canonical" href="https://www.alphafm.com.br/playlist" />
      </Helmet>

      <div className="ContainerPlaylist">
        <p>
          As playlists da Alpha FM foram criadas especialmente para você, caro
          ouvinte! Nós pensamos em seus hábitos, em tudo o que você mais gosta.
          Você pode curtir todos estilos de música; do momento em que acorda até a
          hora do descanso. Escolha sua playlist agora, e embarque nessa jornada
          com as melhores seleções da Alpha FM!
        </p>
        <div className="playlistItems">
          {data.map((row, key) => (
            <CardImagePlaylist
              key={key}
              data={{
                title: row.nome,
                subtitle: row.resumo,
                link: "ouvir agora",
                to: `/playlist/${row.id_playlist}`,
                load: load,
                image: row.imagem_thumb,
                height: "370px",
                upload: "https://beta.alphafm.com.br/uploads/playlists/"
              }}
            />
          ))}
        </div>
      </div>
    </>
  );
}
