import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { api } from "../../../Api/app";
import IconPlayer from "../../Icons/IconPlayer";
import IconShare from "../../Icons/IconShare";
import RadioPodcast from "../RadioPodcast/RadioPodcast";
import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton, FacebookIcon, WhatsappIcon, LinkedinIcon } from 'react-share';

import "./PodcastInternalMusic.scss";

export default function PodcastInternalMusic({ data = [], load = true, props }) {
  const [dataMusic, setDataMusic] = useState([])
  const [loadMusic, setLoadMusic] = useState(load);
  const [play, setPlay] = useState({})

  const [share, setShare] = useState(false);

  const shareUrl = `https://alphafm.com.br/playlist/${data.id_playlist}`


  // useEffect(() => {
  //   setLoadMusic(true)
  //   // if (data.id_podcast)
  //   //  getData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  async function getData() {
    const response = await api.get(
      `episodios-podcasts/podcast/${data.id_podcast}`,
    );
    setDataMusic(response.data.data)
    setLoadMusic(false)
  }

  async function handleOnNext(e) {

    if (dataMusic[play.indice + 1]) {
      setPlay({
        music: dataMusic[play.indice + 1],
        indice: play.indice + 1
      })
    } else {
      setPlay({
        music: dataMusic[0],
        indice: 0
      })
    }

  }

  async function handleOnPrev(e) {

    if (play.indice > 0) {
      setPlay({
        music: dataMusic[play.indice - 1],
        indice: play.indice - 1
      })
    } else {
      setPlay({
        music: dataMusic[dataMusic.length - 1],
        indice: dataMusic.length - 1
      })
    }

  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:url" content={`https://www.alphafm.com.br/podcast/${data.id_podcast}`} />
        <meta property="og:type" content='podcast' />
        <meta property="og:title" content={data.titulo} />
        <meta property="og:description" content={data.texto} />
        <meta property="og:image" content={data.imagem} />

        <title>Podcast</title>
        <link rel="canonical" href="https://www.alphafm.com.br/podcast" />
      </Helmet>

      <div
        className={`podcastInternalMusic ${play.indice > 0 ? "play" : ""}`}
      >
        <iframe
          title='a'
          loading='lazy'
          style={{ borderRadius: '12px' }}
          src={`https://open.spotify.com/embed/playlist/${data.id_podcast}?utm_source=generator`}
          width="100%"
          height="100%"
          frameBorder="0"
          allowfullscreen=""
          allow="clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        >
        </iframe>
        <div className="podcastInternalMusicSkeleton">
          <Skeleton width='100%' height='100%' style={{
            borderRadius: '0.75rem',
          }} />
        </div>
      </div>
    </>
  );
}
