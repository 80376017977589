import React, { useEffect, useState } from "react";
import { api } from '../../../Api/app';
import RowControl from "../../../Components/RowControl/RowControl";
import ColumnControl from "../../../Components/ColumnControl/ColumnControl";
import WidgetControl from "../../../Components/WidgetControl/WidgetControl";
import ColumnistsPodcast from "../../../Components/Widgets/ColumnistsPodcast/ColumnistsPodcast";
import PublicationsColumnist from "../../../Components/Widgets/PublicationsColumnist/PublicationsColumnist";
import { init, sendView } from "../../../GA/ga4";
export default function ColumnistsInfo(props) {

    const [load, setLoad] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
      setLoad(true);
      api.get(`colunistas/slug/${props.match.params.slug}`,).then((res) => {
        setData(res.data.data[0]);
        setLoad(false);
      });
    }, [props.match.params.slug]);

    useEffect(() => {
      // init()
      sendView(`/colunista/${props.match.params.slug}`)
    }, [props.match.params.slug])

  return (
    <div id="columnistsInfo" className="page">
      <RowControl>
        <ColumnControl column={8}>
          <WidgetControl title="Publicações">
            <PublicationsColumnist data={data} load={load} />
          </WidgetControl>
        </ColumnControl>

        <ColumnControl column={4}>
          <WidgetControl title={data.colunista}>
            <ColumnistsPodcast data={data} load={load} />
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
