import React from "react";
import "./ModalMessage.scss";
import SucessImg from "../../Images/sucess.png";
import ErrorImg from "../../Images/cancel.png";

export default function ModalMessage({
  id = "overlayModalMessage",
  onClose = () => {},
  message,
  messageButton,
  modal,
  error
}) {
  const handleOutsideClick = (e) => {
    if (e.target.id === id) onClose();
  };

  return (
    <div id={id} className={`overlayModalMessage ${modal && 'overleyModalVisible'}`} onClick={handleOutsideClick}>
      <div className={`cardModal`}>
        <div className="cardContent">
          <div className="infoModal">
            <img src={messageButton === 'Sucesso' ? SucessImg : ErrorImg} alt="Imagem illustrativa" />
            <span className="messageModal">{message}</span>
          </div>
          <div className="buttonModal">
            <button className={`buttonSucess ${error.status === 'error' ? 'error' : ''}`} onClick={onClose}>
              {messageButton}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
