import React, { useEffect } from "react";
import ColumnControl from "../../Components/ColumnControl/ColumnControl";
import RowControl from "../../Components/RowControl/RowControl";
import WidgetControl from "../../Components/WidgetControl/WidgetControl";
import ColumnistsItems from "../../Components/Widgets/ColumnistsItems/ColumnistsItems";
import { init } from "react-facebook-pixel";
import { sendView } from "../../GA/ga4";

export default function Columnists(props) {
  useEffect(() => {
    // init()
    sendView("/colunista")
  }, [])

  return (
    <div id="columnists" className="page">
      <RowControl>
        <ColumnControl column={12}>
          <WidgetControl title="Colunistas">
            <ColumnistsItems />
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}
