import React from "react";

export default function IconCamera({ size = 16, color = "#252428" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_903:1645)">
        <path
          d="M14.75 12C14.75 12.3315 14.6207 12.6495 14.3905 12.8839C14.1604 13.1183 13.8482 13.25 13.5227 13.25H2.47727C2.15178 13.25 1.83962 13.1183 1.60946 12.8839C1.3793 12.6495 1.25 12.3315 1.25 12V5.125C1.25 4.79348 1.3793 4.47554 1.60946 4.24112C1.83962 4.0067 2.15178 3.875 2.47727 3.875H4.93182L6.15909 2H9.84091L11.0682 3.875H13.5227C13.8482 3.875 14.1604 4.0067 14.3905 4.24112C14.6207 4.47554 14.75 4.79348 14.75 5.125V12Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 10.25C9.24264 10.25 10.25 9.24264 10.25 8C10.25 6.75736 9.24264 5.75 8 5.75C6.75736 5.75 5.75 6.75736 5.75 8C5.75 9.24264 6.75736 10.25 8 10.25Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_903:1645">
          <rect
            width="15"
            height="15"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
