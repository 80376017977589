import React from "react";

export default function CloseMenu({ size = 42, color = "#000" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 42 42"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32 10L11 31"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 10L32 31"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
