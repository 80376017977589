/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ColumnControl from "../../Components/ColumnControl/ColumnControl";
import RowControl from "../../Components/RowControl/RowControl";
import WidgetControl from "../../Components/WidgetControl/WidgetControl";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import PaoDeAcucarMomentoSaborBL1 from '../../Images/PaoDeAcucarMomentoSaborBL1.png';
import PaoDeAcucarMomentoSaborBL2 from '../../Images/PaoDeAcucarMomentoSaborBL2.png';
import PaoDeAcucarMomentoSaborBL3 from '../../Images/PaoDeAcucarMomentoSaborBL3.png';
import PaoDeAcucarMomentoSaborBL4 from '../../Images/PaoDeAcucarMomentoSaborBL4.png';
// import Ep1 from '../../Images/PaoDeAcucar.jpg';
import "./PaoDeAcucar.scss";
import { useEffect } from "react";
import { init, sendView } from "../../GA/ga4";

export default function PaoDeAcucar(props) {
  const [mouseEnter, setMouseEnter] = useState(false);

  useEffect(() => {
    // init()
    sendView("/momento-do-sabor")
  }, [])

  return (
    <div id="paoDeAcucar" className="page">
      <RowControl>
        <ColumnControl column={12}>
          <WidgetControl title="">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <img className="banner" src={PaoDeAcucarMomentoSaborBL1} alt="Banner" />

              <div id="conteudoPaoDeAcucar">
                <img className="aboutProject" src={PaoDeAcucarMomentoSaborBL2} alt="Resumo sobre o projeto" />
                <img className="aboutProject" src={PaoDeAcucarMomentoSaborBL3} alt="Sobre Thiago Sodré" />
              </div>

              <div className="slider">
                <Carousel
                  autoPlay={!mouseEnter}
                  infiniteLoop
                  interval={3000}
                  showThumbs={false}
                  showStatus={false}
                  useKeyboardArrows
                  stopOnHover={true}
                  emulateTouch
                  className="presentation-mode"
                >
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 1 - 08/08/23'}
                    subtitle={'Blend de carnes para hambúrguer'}
                    videoURI={'https://drive.google.com/file/d/10d2pGQUdI0FZpluBaz-KilAzYkpNq-s0/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIEJsZW5kIGRlIGNhcm5lcyBwYXJhIGhhbWLDunJndWVy/MTA5Ny8wYS8xY2QwYWVlZTI5NTcyMjliN2NlNTY3ZGE3M2M4YmI4NTMzZDJjYy5tcDM=/e8b9577d4ac6f0b88e4a8a0e3c8b8bfcac42a21d/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O blend é uma combinação entre cortes magros e os com mais gordura. Do contrário, o hambúrguer ficará seco e com pouco sabor. A proporção pode variar, mas metade de cada tipo já funciona bem, e o ideal é ficar com um percentual de gordura de 20%. Então a carne gorda deve ser moída junto com a gordura. Quando for modelar os hambúrgueres, pese cada carne para ficarem de 150g a 180g.</p>
                        </div>
                        <div>
                          <h4 style={{ textAlign: "initial", fontSize: 14 }}>Tempero</h4>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Usando um bom blend de carnes, não é preciso mascarar o sabor com nenhum outro tempero (queremos destacar o próprio sabor da carne!) Então usar um bom sal e pimenta do reino na grande maioria das vezes já é suficiente.</p>
                        </div>
                        <div>
                          <h4 style={{ textAlign: "initial", fontSize: 14 }}>Queijos</h4>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O queijo tem papel super importante no hambúrguer também, e como a carne, tudo depende do seu gosto e da personalidade que quer dar para o seu hambúrguer. Queijo prato, americano, cheddar, gruyere, gorgonzola, são uns dos mais usados.</p>
                        </div>
                        <div>
                          <h4 style={{ textAlign: "initial", fontSize: 14 }}>Pão</h4>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Um bom hambúrguer pode ser destruído por um pão ruim. É muito importante usar um bom pão de qualidade, porque é ele que vai segurar todo o seu sanduíche. Você pode optar pelo clássico com gergelim, brioche, pão australiano ou até mesmo o pão francês.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Outra dica importante é usar uma boa frigideira, mais grossa, em fogo médio/alto para poder dar uma boa caramelizada na carne e evitar soltar água quando colocar para grelhar.</p>
                        </div>
                      </div>
                    }
                  />
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 2 - 15/08/23'}
                    subtitle={'Pizza de abobrinha'}
                    videoURI={'https://drive.google.com/file/d/1dCUjL-EX5TOnz8ux2RQ9Sgc-Q0ssqJWN/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIFBpenphIGRlIGFib2JyaW5oYQ==/MTA5Ny9lMy9iMjQxMTk3Mzc3MjJlZWU2NGM5YTg4MDBjN2I4NDY0ODNmOWM4OC5tcDM=/da55a0bf427400a932c22822c70c58da6cb13b3d/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <h4 style={{ textAlign: "initial", fontSize: 14 }}>Ingredientes</h4>
                          <ul style={{ textAlign: "initial", fontSize: 14 }}>
                            <li>&#x2022; Tomates italianos maduros</li>
                            <li>&#x2022; Abobrinha</li>
                            <li>&#x2022; Queijo</li>
                            <li>&#x2022; Azeite</li>
                            <li>&#x2022; Sal e pimenta do reino</li>
                            <li>&#x2022; Orégano</li>
                            <li>&#x2022; Gorgonzola</li>
                            <li>&#x2022; Brócolis</li>
                          </ul>
                        </div>
                        <div>
                          <h4 style={{ textAlign: "initial", fontSize: 14 }}>Modo de preparo</h4>
                          <ul style={{ textAlign: "initial", fontSize: 14, display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                            <li><strong>1.</strong> Corte a abobrinha ao meio de comprido e raspe as sementes com uma colher, para fazer tipo uma canoa;</li>
                            <li><strong>2.</strong> Para o molho de tomate, corte o tomate ao meio e passe ele no ralador, na parte mais grossa (desse jeito você pega toda a polpa sem a casca);</li>
                            <li><strong>3.</strong> Passe em uma peneira para retirar o excesso de água e tempere com azeite, sal, pimenta do reino e orégano;</li>
                            <li><strong>4.</strong> Coloque o molho na abobrinha e recheie. A primeira vou colocar só queijo muçarela e na segunda vou colocar gorgonzola e brócolis;</li>
                            <li><strong>5.</strong> Leve para assar ao forno ou na airfryer a 180°C durante 10 a 15 minutos, até ficar bem gratinado.</li>
                          </ul>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Você pode variar e usar o recheio que quiser, porque no pão de açúcar, o que você mais tem é variedade e qualidade de ingredientes.</p>
                        </div>
                      </div>
                    }
                  />
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 3 - 22/08/23'}
                    subtitle={'Cortes de legumes'}
                    videoURI={'https://drive.google.com/file/d/1waoGVbGvJKCIGKiy6zsFtm-Wo1cizxb-/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIENvcnRlcyBkZSBsZWd1bWVz/MTA5Ny8yNC8xMTJjMDE3MGIzY2U4NmI3YWViMzc1MzdiZjBjMzUxNjVmNmY3Zi5tcDM=/e7461e54b41dcbd6fcadd1e62012006dab042edd/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Depois de tirar a casca, você pode usar o próprio descascador para fazer fatias bem finas, essa é uma forma perfeita para enfeitar a salada;</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Outra forma é mais artística: vai cortando e rodando, como se estivesse apontando um lápis;</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Tem também a opção de palitinhos, assim podemos comer com pastinhas ou puro mesmo. É só cortar o legume na horizontal e repetir até fazer os palitinhos do tamanho que desejar. Fica bem gostoso!</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Por fim, podemos também usar o mandolin, que é um aparelho de cozinha bem prático, com ele dá para fazer espaguete de cenoura ou abobrinha, que funciona muito bem também.</p>
                        </div>
                      </div>
                    }
                  />
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 4 - 29/08/23'}
                    subtitle={'Sorbet de frutas'}
                    videoURI={'https://drive.google.com/file/d/1lZMKfT1pOxfj41Lbp5Ws5pifa95Zj2Rv/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIFNvcmJldCBkZSBmcnV0YXM=/MTA5Ny9jMy9mNGRlZmExMjVkMGU0MDMyMzczZDU0NGUxZTU3MDBkN2YwNDIwZS5tcDM=/3d2aa7b8f4e5bfddf204e9ff76483d7193aa0c82/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Essa receita foi feita com maçã, mas pode ser feito com qualquer fruta, é só deixar no congelador de um dia para o outro!</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Outro ponto importante é usar o microplane, um ralador bem fininho para ralar a fruta e fazer a "neve".</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O sorbet foi finalizado com mel, nozes e pistache.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O resultado surpreendeu: a textura fica super leve e ficou delicioso.</p>
                        </div>
                      </div>
                    }
                  />
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 5 - 05/09/23'}
                    subtitle={'Ponto da massa'}
                    videoURI={'https://drive.google.com/file/d/1LHSsxF_B5fxlZ0upTT2BXZofP04KsoNw/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIFBvbnRvIGRhIG1hc3Nh/MTA5Ny8xMS81YTYwYmFmOWIzNzc4NjA0ZDNmYmEzZGMzOThkODdlYTY1N2IyNS5tcDM=/85422f88f2470d5be4db3491877fc16b9360e069/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Começamos com uma panela com bastante água em fogo alto, o ideal é aproximadamente um litro de água para cada 100 gramas de massa.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O sal é uma parte essencial do processo, então podemos colocar o suficiente para que ela fique parecida com a água do mar.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Quando essa água estiver fervendo bastante, colocamos a massa e agora é só seguir as instruções do tempo de cozimento!</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Após alguns segundos, você pode mexer um pouco e ir soltando para a massa não grudar e dificultar o processo.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Depois é só escorrer e servir imediatamente! A dica de ouro que deixamos para o final é: NUNCA lave o seu macarrão, isso tira o amido que é o que faz o molho grudar na massa.</p>
                        </div>
                      </div>
                    }
                  />
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 6 - 12/09/23'}
                    subtitle={'Batata fritas sequinhas'}
                    videoURI={'https://drive.google.com/file/d/1lfXE37oWrjwtERZelm_T2TwQNH_dZUfB/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIEJhdGF0YSBGcml0YQ==/MTA5Ny85NS80OGMxMzFkMzM3YmRhODU2YTVhNzQ3OGI0YjA2ZDdlZGQxMWE4OC5tcDM=/31ba1b2ac4385a6dbac8f760abf4ab551af5b8f9/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Com a batata já lavada corte em fatias. Ele usou a batata com casca mesmo, mas se quiser, pode retirar.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Em seguida corte em palitinhos (nem muito grosso nem muito fino, o importante é tentar deixar todos eles com o mesmo tamanho).</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O ideal é usar batata asterix, aquela com a casca rosada, mas também dá para fazer com a batata inglesa também. Coloque elas para fritar em óleo não tão quente, aproximadamente 120° a 140°C durante 5 minutos.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Conta aqui nos comentários o que você achou, compartilhe com os amigos e bom apetite!</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O truque aqui é escorrer bem as batatas, espalhar elas em uma assadeira e levar para congelar. Isso vai garantir uma batata com uma casquinha crocante e ainda macia por dentro. Você pode guardar elas congeladas por até 3 meses. Quando quiser comer é só fritar em óleo a 180°C até elas ficarem douradas, secar bem no papel toalha e temperar.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Agora é só aproveitar como quiser!</p>
                        </div>
                      </div>
                    }
                  />
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 7 - 19/09/23'}
                    subtitle={'Merengue de morango'}
                    videoURI={'https://drive.google.com/file/d/1WVMlrq6rADgMMjZL6lSaYsWv8HvSM6ud/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIE1lcmVuZ3VlIG1vcmFuZ28=/MTA5Ny8zOS8zODYzMGNjZDc2N2JkNTM1YjZkY2RlMWFjODBlMjQ1ZDRjNjhkNS5tcDM=/2a03a91f7cd4b4c2b9406a7ae668477ae152319f/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Para fazer o creme: 300ml de creme de leite fresco bem gelado e 2 colheres de sopa de açúcar. Misture bem os dois ingredientes com a batedeira até virar chantilly.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Em seguida, rale uma barra de chocolate branco e incorpore no creme. Agora é só deixar na geladeira até a hora da montagem.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Para montar, você pode usar um copo ou taça. Coloque um pouco do creme no fundo, em seguida arrume em volta os morangos cortados.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Adicione novamente o creme e suspiros quebrados ao meio, e depois mais uma camada de morangos e creme. Finalize com suspiros inteiros e morangos cortados.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Corte com cuidado em fatias finas e abra para fazer um leque. Pronto, agorA é só deixar na geladeira até a hora de servir!</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O merengue fica super leve e crocante por conta do chocolate e do suspiro. </p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}></p>
                        </div>
                      </div>
                    }
                  />
                  <ContentPaoDeAcucar
                    set={setMouseEnter}
                    title={'Episódio 8 - 26/09/23'}
                    subtitle={'Peito de frango perfeito'}
                    videoURI={'https://drive.google.com/file/d/1xQgEDKp0XdOeySLveXsN4OYDi7xHy_cG/preview'}
                    audioURI={'https://d3vullwu47dvti.cloudfront.net/1097/TW9tZW50byBkbyBTYWJvciAtIEZyYW5nbyBQZXJmZWl0bw==/MTA5Ny82Ny9hMzYwZDk5MWI2ZTU1ZDc3NjllNmVkZmQ4Njg2OGFiMDRiZDIxNi5tcDM=/3cd146f554beca926cc74d5e5b2bc3efcc913956/servidor38.brlogic.com'}
                    body={
                      <div style={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>O ideal é usar o peito de frango com pele, mas sem também funciona.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Fure o frango inteiro com uma faca e em seguida tempere com sal, pimenta do reino e azeite dos dois lados.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Na opinião do chef, a melhor forma de cozinhar é na airfryer: pré aqueça a airfryer a 200°C, em seguida coloque o frango e deixe assar por 10 minutos.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Quando o tempo acabar, não abra a airfryer, deixe o frango mais 10 minutos lá dentro com ela desligada, para a carne terminar de assar no calor residual.</p>
                        </div>
                        <div>
                          <p style={{ textAlign: "initial", fontSize: 14 }}>Por conta do calor intenso, o frango fica dourado e em seguida com uma temperatura mais baixa ele termina de assar e continua molhadinho.</p>
                        </div>
                      </div>
                    }
                  />
                </Carousel>
              </div>

              <img className="banner" src={PaoDeAcucarMomentoSaborBL4} alt="Banner" />
            </div>
          </WidgetControl>
        </ColumnControl>
      </RowControl>
    </div>
  );
}

function ContentPaoDeAcucar({ title, videoURI, audioURI, subtitle, body, set }) {
  return (
    <div
      key="content-0"
      id="carouselItem"
      onMouseEnter={() => set(true)}
      onMouseLeave={() => set(false)}
    >
      <div id="carouselItemVideo">
        <iframe title="video" src={videoURI} width="940" height="380" allow="autoplay"></iframe>
        <iframe title="audio" width="100%" height="76" scrolling="no" allowTransparency="true" frameborder="no" src={audioURI}></iframe>
      </div>

      <div id="carouselItemText">
        <h3 style={{ fontSize: 14 }}>{title}</h3>
        <h4 style={{ fontSize: 16 }}>{subtitle}</h4>
        {body}
      </div>
    </div >
  )
}
