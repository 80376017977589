import React from "react";
import Button from "../../Buttons/Button/Button";
import CardSchedule from "../../../Components/Cards/CardSchedule";

import "./ReadMore.scss";

export default function ReadMore({ data = [], load = true, handleOnLoadMore = () => {} }) {
  return (
    <div className="read-more">
      {data.map((row, key) => (

        <CardSchedule
          key={key}
          data={{
            data: row.data_post,
            category: row.categoria,
            title: row.titulo,
            subtitle: row.resumo,
            link: "ler mais",
            to: `/materia/${row.slug}`,
            load: load,
            image: row.imagem,
            height: '230px'
          }}
        />
      ))}

        {data.length > 0 && (
          <Button
            onClick={handleOnLoadMore}
            className={`btn primary`}
          >
            carregar mais notícias
          </Button>
        )}
    </div>
    
  );
}
