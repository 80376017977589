
import React, { useEffect, useState } from 'react';
import RowControl from '../../../Components/RowControl/RowControl';
import ColumnControl from '../../../Components/ColumnControl/ColumnControl';
import WidgetControl from '../../../Components/WidgetControl/WidgetControl';
import DetailedNews from '../../../Components/Widgets/DetailedNews/DetailedNews';
import ReadMore from '../../../Components/Widgets/ReadMore/ReadMore';
import ScheduleItemsInternal from '../../../Components/Widgets/ScheduleItemsInternal/ScheduleItemsInternal';
import { api } from '../../../Api/app'
import { init, sendView } from '../../../GA/ga4';

export default function Details(props) {
    const [load, setLoad] = useState(true)
    const [data, setData] = useState({})
    const [dataMore, setDataMore] = useState([{}])
    const [plus, setPlus] = useState(3)

    useEffect(() => {
        // init()
        sendView(`/materia/${props.match.params.slug}`)
    }, [props.match.params.slug])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, plus])

    async function getData()
    {
        setLoad(true)
        const response = await api.get(`posts/slug/${props.match.params.slug}`)
        setData(response.data.data)
        
        const responseMore = await api.get(`posts/categoria?paginacao=true&itens=${plus}&categoria=${response.data.data.id_categoria}`)
        setDataMore(responseMore.data.data.data.filter( obj => obj.id_post !== response.data.data.id_post))
        setLoad(false)
    }    

    return(
        <div id="details" className="page">

            <RowControl>

                <ColumnControl column={10}>
                    <WidgetControl title={ data.titulo } load={load}>

                        <DetailedNews load={load} data={data}/>

                    </WidgetControl>
                </ColumnControl>

                <ColumnControl column={3}>

                    { dataMore.length > 0 &&
                        <WidgetControl title="Leia também">
                            <ReadMore 
                                load={load}
                                data={dataMore}
                                handleOnLoadMore={() => setPlus(plus + 3)}
                            />
                        </WidgetControl>
                    }

                    <WidgetControl title="Agenda">
                        <ScheduleItemsInternal />
                    </WidgetControl>
                </ColumnControl>

            </RowControl>

        </div>
    )

}