import React from 'react'
import Notfound from '../../Icons/NotFound'
import { AnnouncedNewsSchedule } from '../Anuncios/Pix'

import "./NotFound.scss"

export default function NotFound()
{

    return(
        <div id="ContainerNotfound">

            <div className="notFound">
                <h1>Não encontramos nada por aqui...</h1>
                <span>Que tal escolher outra categoria ?</span>
                <Notfound />
            </div>

            {/* <div className="AnnouncedSchedule">
                <AnnouncedNewsSchedule />
            </div> */}
            

        </div>
    )

}