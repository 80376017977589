import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { usePlay } from "../../Context/PlayContext";
import IconPause from "../Icons/IconPause";
import IconPlay from "../Icons/IconPlay";
import IconVolume from "../Icons/IconVolume";

import ImageDefault from '../../Images/AlphaClassics2.jpeg'

import "./PlayerAlphaClassics.scss";

export default function PlayerAlphaClassics() {
  const { isPlayingClassics, setIsPlayingClassics, setPlayingStateClassics } = usePlay();
  const audioRef = useRef(null);

  const [status, setStatus] = useState({
    tocando: [
      {
        song: "...",
        singer: "...",
      },
    ],
  });

  useEffect(() => {
    if (!audioRef.current) return;

    if (isPlayingClassics) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    getStatus();
  }, [isPlayingClassics]);

  async function getStatus() {
    const status = await Axios.get(
      "https://players.gc2.com.br/cron/alphafm/results.json?_=" +
        new Date().getTime(),
    );

    {
      status.data.musicas && setStatus({ ...status.data.musicas[0] });
    }
  }

  return (
    <div id="playerClassics" className={`${isPlayingClassics && 'openClassics'}`}>
      {isPlayingClassics === true ? (
        <div className="infos">
            <div className="top">
              <span>
                Ouça <b>ao vivo</b> <u>|</u> SP
              </span>
              <IconVolume />
            </div>

            <div className="music">
              <b>Agora:</b>{" "}
              <span>
                Tocando Alpha Classics
                {/* {status.tocando[0].song} - {status.tocando[0].singer} */}
              </span>
            </div>
          </div>
       ) : (
        <span>
          ALPHA Classics
        </span>
       )}

      <div
        className={`album`}
        style={{ backgroundImage: `url(${ImageDefault})` }}
      >
        {isPlayingClassics ? (
          <IconPause onClick={() => setIsPlayingClassics(!isPlayingClassics)} />
        ) : (
          <IconPlay onClick={() => setIsPlayingClassics(!isPlayingClassics)} />
        )}

        <audio
          src="https://playerservices.streamtheworld.com/api/livestream-redirect/89BRASILAAC.aac?dist=site-alphaclassics"
          ref={audioRef}
          autoPlay
          onPlay={() => setPlayingStateClassics(true)}
          onPause={() => setPlayingStateClassics(false)}
        />
      </div>
    </div>
  );
}
