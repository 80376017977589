import React, { useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import ColumnControl from "../../Components/ColumnControl/ColumnControl";
import WidgetControl from "../../Components/WidgetControl/WidgetControl";
import Agenda from "../../Components/Widgets/Agenda/Agenda";
import AoVivo from "../../Components/Widgets/AoVivo/AoVivo";
import Destaques from "../../Components/Widgets/Destaques/Destaques";
import Podcast from "../../Components/Widgets/Podcast/Podcast";
import Youtube from "../../Components/Widgets/Youtube/Youtube";
import Banner from "../../Components/Widgets/Banner/Banner";
import { init, sendView } from "../../GA/ga4";

export default function Index(props) {

  const explodeDefault = [
    // {
    //   column: 4,
    //   title: 'Alpha FM ao vivo',
    //   widgets: ['aovivo', 'agenda' ]
    // },
    {
      column: 4,
      title: 'Alpha FM ao vivo',
      widgets: ['aovivo', 'banner', 'agenda']
    },
    {
      column: 2,
      title: 'Podcasts',
      widgets: ['podcasts']
    },
    {
      column: 3,
      title: 'Destaques',
      widgets: ['destaques']
    },
    {
      column: 4,
      title: 'YouTube Alpha FM',
      widgets: ['youtube']
    }
  ]

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const busca = props.location.search.replace('?busca=', '')
  // const [explore, setExplore] = useState(window.localStorage.getItem('exploreInitial21') ? JSON.parse(window.localStorage.getItem('exploreInitial21')) : explodeDefault)
  const [explore, setExplore] =  useState(explodeDefault)

  const Widgets = [
    {
      value: 'aovivo',
      render: <AoVivo busca={busca}/>
    },
    // windowDimensions.width < 800 ? {value: 'aovivo', render: <AoVivo busca={busca}/>} : {},
    {
      value: 'banner',
      render: <Banner busca={busca}/>
    },
    {
      value: 'agenda',
      render: <Agenda busca={busca}/>
    },
    {
      value: 'podcasts',
      render: <Podcast busca={busca}/>
    },
    {
      value: 'destaques',
      render: <Destaques busca={busca}/>
    },
    {
      value: 'youtube',
      render: <Youtube busca={busca}/>
    },
  ]

  useEffect(() => {

    window.localStorage.setItem('exploreInitial21', JSON.stringify(explore))

  }, [explore])

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // init()
    sendView('/')
  }, [])

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    }
  }

  function handleOnChangeColumn(indice, value) {
    explore[indice].column = value
    setExplore([...explore])
  }

  return (
    <div id="index" className="page">
      {windowDimensions.width < 800 ?
        <div className="row" list={explore} setList={setExplore} >
          {explore.map((row, key) =>
            <ColumnControl key={key} column={row.column}>
              <WidgetControl column={row.column} busca={busca} onChangeColumn={(value) => handleOnChangeColumn(key, value)} icon={row.icon} title={row.title}>
                {row.widgets.map((widget) =>
                  Widgets.filter(obj => obj.value === widget).length > 0 && Widgets.filter(obj => obj.value === widget)[0].render
                )}
              </WidgetControl>
            </ColumnControl>
          )}
        </div>
        :
        <ReactSortable 
          animation={200} 
          className="row" 
          list={explore} 
          setList={setExplore}
        >
          
          {explore.map((row, key) =>
            <ColumnControl key={key} column={row.column}>
              <WidgetControl id={`widget${key}`} editable={true} busca={busca} column={row.column} onChangeColumn={(value) => handleOnChangeColumn(key, value)} icon={row.icon} title={row.title}>
                {row.widgets.map((widget) =>
                <>
                  {Widgets.filter(obj => obj.value === widget).length > 0 && Widgets.filter(obj => obj.value === widget)[0].render}
                </>
                )}
              </WidgetControl>
            </ColumnControl>
          )}
        </ReactSortable>
      }
    </div>
  );
}