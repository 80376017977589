import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  // useLocation,
} from "react-router-dom";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

//views
import Index from './Pages/Index/Index'
import Layout from './Components/Layout/Layout'
import Noticias from './Pages/Noticias/Noticias'
import Details from './Pages/Noticias/Details/Details'
import Columnists from './Pages/Columnists/Columnists'
import ColumnistsInfo from './Pages/Columnists/ColumnistsInfo/ColumnistsInfo'
import Promotion from './Pages/Promotion/Promotion'
import PromotionInternal from './Pages/Promotion/PromotionInternal/PromotionInternal'
import Schedule from './Pages/Schedule/Schedule'
import ScheduleInternal from './Pages/Schedule/ScheduleInternal/ScheduleInternal'
import Playlist from './Pages/Playlist/Playlist'
import PlaylistInternal from './Pages/Playlist/PlaylistInternal/PlaylistInternal'
import Podcast from './Pages/Podcast/Podcast'
import PodcastInternal from './Pages/Podcast/PodcastInternal/PodcastInternal'
import ScheduleNotfound from './Pages/Schedule/ScheduleNotfound/ScheduleNotfound'
import Registrations from './Pages/Registragions/Registrations'
import PlayProvider from './Context/PlayContext'
import Profile from './Pages/Profiles/Profiles'

import { AuthProvider } from './Context/AuthContext'
import NewPassword from "./Pages/Password/Password";
import Player from "./Pages/Player/Player";
import PaoDeAcucar from "./Pages/PaoDeAcucar/PaoDeAcucar";
import { init } from "./GA/ga4";
//export function default
export default function Routes(props) {
  // useEffect(() => {
  //   init()
  // }, [])

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          window.localStorage.getItem("token") ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }
  return (
    <AuthProvider>
      <PlayProvider>
        <Router>
          <ReactNotification />

          <Switch>
            <Layout {...props}>
              <Route exact path="/" component={Index} />
              <Route exact path="/noticias" component={Noticias} />
              <Route exact path="/noticias/culturaSP" component={Noticias} />
              <Route exact path="/noticias/cultentr" component={Noticias} />
              <Route exact path="/noticias/bem-estar" component={Noticias} />
              <Route exact path="/noticias/noticias" component={Noticias} />
              <Route exact path="/noticias/coronavirus" component={Noticias} />
              <Route exact path="/noticias/pets" component={Noticias} />
              {/* <Route exact path="/artigo/:slug" component={Details} /> */}
              <Route exact path="/materia/:slug" component={Details} />
              <Route exact path="/colunista" component={Columnists} />
              <Route exact path="/colunista/:slug" component={ColumnistsInfo} />
              <Route exact path="/promocoes" component={Promotion} />
              <Route
                exact
                path="/promocoes/:slug"
                component={PromotionInternal}
              />
              <Route exact path="/agenda" component={Schedule} />
              <Route exact path="/agenda/:slug" component={ScheduleInternal} />
              <Route
                exact
                path="/agenda/:id/notfound"
                component={ScheduleNotfound}
              />
              <Route exact path="/playlist" component={Playlist} />
              <Route exact path="/playlist/:id" component={PlaylistInternal} />
              <Route exact path="/aovivo" component={Player} />
              <Route exact path="/podcast" component={Podcast} />
              <Route exact path="/podcast/:id" component={PodcastInternal} />
              <Route exact path="/cadastro" component={Registrations} />
              <Route exact path="/perfil" component={Profile} />

              <Route exact path="/momento-do-sabor" component={PaoDeAcucar} />

              <Route exact path="/nova-senha/:token" component={NewPassword} />

              <PrivateRoute exact path="/private">
                <Route component={Index} />
              </PrivateRoute>
            </Layout>
          </Switch>
        </Router>
      </PlayProvider>
    </AuthProvider>
  );
}
