import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { api } from "../../../Api/app";
import Datetime from "../../../Helper/Datetime";
import CardError from "../../../Components/Cards/CardError";

import "./Podcast.scss";
import moment from "moment";

export default function Podcast({ busca }) {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoad(true);
    api.get(`podcasts-home/home`).then((res) => {
      console.log('busca', busca, res)
      setData(res.data.data);
      setLoad(false);
    }).catch(() => {
      setData([]);
      setLoad(false);
    });
  }, [busca]);

  return data.length <= 0 ? (
    <>
      {data.length <= 0 && !load && (
        <CardError />
      )}
      {load && (
        <div className="podcast">
          {[1, 2, 3].map((pod) => (
            <div key={`pod-${pod}`} className="cardImage">
              <Skeleton className="cardImage_skeleton" width="100%" height="100%" />
              <span className="cardDate">
                <Skeleton height="100%" width='60%' />
              </span>
              <h2 className="cardTitle">
                <Skeleton height="100%" />
              </h2>
              <p className="cardSubTitle">
                {[100, 75].map((item, i) => (
                  <Skeleton key={i} width={`${item}%`} />
                ))}
              </p>
              <span className="cardLink imageDark">
                <Skeleton height="100%" width='50%' />
              </span>
            </div>
          ))}
        </div>
      )}
    </>
  ) : (
    <div className="podcast">
      {data.map((row) => (
        <Link
          to={`/podcast/${row.id_podcast}`}
          key={row.id_podcast}
          className="cardImage"
        >
          {load === false ? (
            <div className="imagess imageDark">
              <img src={row.imagem_ep} alt={row.titulo} />
            </div>
          ) : (
            <Skeleton width="100%" height="100%" />
          )}

          <span className="cardDate">
            {load === false ? (
              Datetime.string(row.data_ep)
              // moment(row.data_ep)
              //   .format('DD/MM/YYYY HH:mm' )
            ) : (
              <Skeleton height="100%" />
            )}
          </span>

          <h2 className="cardTitle">
            {load === false ? <p>{`${row.titulo_pod}`.toLowerCase()}</p> : <Skeleton height="100%" />}
          </h2>

          <p className="cardSubTitle">
            {load === false ? row.texto_ep : <Skeleton height="100%" />}
          </p>

          <span className="cardLink imageDark">
            {load === false ? "Ouvir agora" : <Skeleton height="100%" />}
          </span>
        </Link>
      ))}
    </div>
  );
}
