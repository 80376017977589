import React, { createContext, useContext, useEffect, useState } from "react";

const PlayContext = createContext({});

function PlayProvider({ children }) {
    const [isPlaying, setIsPlaying] = useState(false)
    const [isPlayingMpb, setIsPlayingMpb] = useState(false)
    const [isPlayingClassics, setIsPlayingClassics] = useState(false)
    const [isPlayingPlaylist, setIsPlayingPlaylist] = useState(false)
    const [isPlayingPodcast, setIsPlayingPodcast] = useState(false)
    const [isPlayingDayling, setIsPlayingDayling] = useState(true)

    function setPlayingStateDayling(state) {
      setIsPlayingDayling(state)
      
      if(isPlayingDayling === true) {
        setIsPlaying(false)
        setIsPlayingMpb(false)
        setIsPlayingClassics(false)
      }
    }

    function setPlayingStatePodcast(state) {
      setIsPlayingPodcast(state)

      if(isPlayingPodcast === false) {
        setIsPlaying(false)
        setIsPlayingMpb(false)
        setIsPlayingClassics(false)
      }
    }

    function setPlayingStatePlaylist(state) {
      setIsPlayingPlaylist(state)

      if(isPlayingPlaylist === false) {
        setIsPlaying(false)
        setIsPlayingMpb(false)
        setIsPlayingClassics(false)
      }
    }

    function setPlayingState(state) {
      setIsPlaying(state)

      if(isPlaying === true) {
        setIsPlayingMpb(false)
        setIsPlayingClassics(false)
        setIsPlayingPlaylist(false)
        setIsPlayingPodcast(false)
        setIsPlayingDayling(false)
      }
    }

    function setPlayingStateMpb(state) {
      setIsPlayingMpb(state)

      if(isPlayingMpb === true) {
        setIsPlaying(false)
        setIsPlayingClassics(false)
        setIsPlayingPlaylist(false)
        setIsPlayingPodcast(false)
        setIsPlayingDayling(false)
      }
    }

    function setPlayingStateClassics(state) {
      setIsPlayingClassics(state)

      if(isPlayingClassics === true) {
        setIsPlaying(false)
        setIsPlayingMpb(false)
        setIsPlayingPlaylist(false)
        setIsPlayingPodcast(false)
        setIsPlayingDayling(false)
        // setIsPlayingClassics(false)
      }
    }

    useEffect(() => {
      console.log(isPlaying, isPlayingClassics, isPlayingMpb)
    }, [isPlaying, isPlayingClassics, isPlayingMpb])

  return (
    <PlayContext.Provider value={{
      isPlaying,
      setIsPlaying,
      setPlayingState,
      isPlayingMpb,
      setIsPlayingMpb,
      setPlayingStateMpb,
      isPlayingClassics,
      setIsPlayingClassics,
      setPlayingStateClassics,
      isPlayingPlaylist,
      setIsPlayingPlaylist,
      setPlayingStatePlaylist,
      isPlayingPodcast,
      setIsPlayingPodcast,
      setPlayingStatePodcast,
      isPlayingDayling, 
      setIsPlayingDayling,
      setPlayingStateDayling
    }}>
      {children}
    </PlayContext.Provider>
  ) 
}

export function usePlay() {
  const context = useContext(PlayContext);
  return context;
}

export default PlayProvider;
