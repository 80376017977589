import React from 'react'

export default function IconPause({id, size = 84, color = "#FFF", onClick}){
    return (
        <svg style={{cursor: 'pointer', filter: 'drop-shadow( 1px 1px 10px #000'}} id={id} onClick={onClick} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" width={size} height={size} preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
            <g fill={color}>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"/>
            </g>
            <rect x="0" y="0" width="16" height="16" fill="rgba(0, 0, 0, 0)" />
        </svg>
    )
}