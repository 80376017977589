
import React from 'react'

export default function ColumnControl({
    children,
    column
})
{

    return(
        <div className={`column c-${column}`}>
            {children}
        </div>
    )

}