import React, { useCallback, useRef, useState } from "react";
import { Form } from "@unform/web";
import { api } from "../../../Api/app";
import ModalTerms from "../../ModalTerms/ModalTerms";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import InputDefault from "../../Buttons/InputDefault";
import SelectDefault from "../../Buttons/SelectDefault";
import CancelImg from "../../../Images/cancel.png";

import "./styles.scss";
import ModalDefault from "../../ModalDefault/ModalDefault";
import ButtonDefault from "../../Buttons/ButtonDefault";

export default function SignUp() {
  const formRef = useRef(null);
  const history = useHistory();
  const [address, setAddress] = useState(false)
  const [isCheck, setIsCheck] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modalTerms, setModalTerms] = useState(false)
  const [modal, setModal] = useState(false);
  const [errorForm, setErrorForm] = useState('')
  
  function onBlurCep(event) {
    const { value } = event.target;

    const cep = value?.replace(/[^0-9]/g, "");

    if (cep?.length !== 8) {
      return;
    }
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        setAddress({ ...data });
      });
  }

  function onCheckTerms() {
    setIsCheck(!isCheck)
  }

  function onChangeTerms() {
    setModalTerms(!modalTerms)
  }


  //  async function handleUpload(e) {
  //   if (e.target.files.length) {
  //     var formData = new FormData();
  //     formData.append("imagem", e.target.files[0], e.target.files[0].name);

  //     let response = await api.post("usuarios/upload", formData);

  //     user.foto = response.data.url;
  //     setUser({ ...user });
  //   }
  // }

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current.setErrors({});
        setLoading(true)

        const schema = Yup.object().shape({
          nome: Yup.string().required("Nome obrigatório"),
          email: Yup.string().required("Email obrigatório").email("Digite e-mail valido"),
          cpf: Yup.string().required("CPF obrigatório"),
          telefone: Yup.string().required("Telefone obrigatório"),
          data_nascimento: Yup.string().required("Data nascimento obrigatório"),
          sexo: Yup.string().required("Sexo obrigatório"),
          interesse: Yup.string().required("Interesse obrigatório"),
          cep: Yup.string().required("CEP obrigatório"),
          endereco: Yup.string().required("Endereço obrigatório"),
          bairro: Yup.string().required("Bairro obrigatório"),          
          estado: Yup.string().required("UF obrigatório"),
          cidade: Yup.string().required("Cidade obrigatório"),
          senha: Yup.string().required("Senha obrigatório"),
          repete_senha: Yup.string().required("Confirmação obrigatório"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post("usuarios/cadastrar", data);

        if (response.data.status === "error") {
          throw response.data.message;
        }

        setLoading(false)

        history.push('/');
        // window.location.reload();

      } catch (err) {
        setLoading(false)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current.setErrors(errors);
          return;
        }

        setErrorForm(err)
        setModal(true);
      }
    },
    [history],
  );

  return (
    <div className="containerSignIn dark">

      <div className="profileData">
        <h1 className="profileDark">Dados do perfil</h1>
        <div className="contentSignIn dark">
          <Form ref={formRef} onSubmit={handleSubmit} onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}>

            <div className="field">
              <InputDefault name="nome" label="Nome" placeholder="Nome" />
              <InputDefault name="email" label="Email" placeholder="Email" />
            </div>

            <div className="fieldGroup">
              <InputDefault name="cpf" mask='cpf' label="CPF" placeholder="CPF" />
              <InputDefault name="telefone" mask="fone" label="Telefone" placeholder="Telefone" />
            </div>

            <div className="fieldGroup">
              <InputDefault name="data_nascimento" mask="date" label="Data nascimento" placeholder="Data nascimento" />
              <SelectDefault name="sexo" label="Sexo" placeholder="Sexo">
                <option value="">Selecione sexo</option>
                <option value="Masculino">masculino</option>
                <option value="Feminino">feminino</option>
                <option value="Prefiro não informar">Prefiro não informar</option>
              </SelectDefault>
            </div>

            <div className="fieldGroup">
              <SelectDefault name="interesse" label="Interesse" placeholder="Quais são seus interesses?">
                <option value="">Quais os assuntos de seu interesse?</option>
                <option value="concurso">Concursos</option>
                <option value="bastidor">Bastidores da rádio</option>
                <option value="noticia">Notícias sobre música</option>
                <option value="session">Alpha Sessions</option>
                <option value="entrevista">Entrevistas</option>
                <option value="noticiaGeral">Notícias gerais (notas jornalísticas)</option>
              </SelectDefault>
            </div>

            <div className="fieldGroup">
              <InputDefault name="cep" label="CEP" mask="cep" placeholder="CEP" onBlur={onBlurCep} />
              <InputDefault name="endereco" label="Endereço" placeholder="Endereço" value={address?.logradouro} />
            </div>

            <div className="fieldGroup">
              <InputDefault name="bairro" label="Bairro" placeholder="Bairro" value={address?.bairro} />
              <InputDefault name="complemento" label="Complemento" placeholder="Complemento" />
            </div>

            <div className="fieldGroup">
              <InputDefault name="estado" label="UF" placeholder="UF" value={address?.uf} />
              <InputDefault name="cidade" label="Cidade" placeholder="Cidade" value={address?.localidade} />
            </div>

            <div className="fieldGroup">
              <InputDefault name="senha" label="Senha" placeholder="Senha" type="password" />
              <InputDefault name="repete_senha" label="Repita a senha" placeholder="Repita a senha" type="password" />
            </div>

            <div className="checkTerms">
                <input
                  className="inputTerms"
                  name="terms"
                  type="checkbox"
                  label="terms"
                  checked={isCheck}
                  onClick={onCheckTerms}
                />

                <span>Li e aceito os</span>
                <button
                  type="button" 
                  className="buttonTerms"
                  onClick={onChangeTerms}
                >
                  termos e condições
                </button>
              </div>

              <ButtonDefault
                type="submit"
                buttonType="buttonDefault"
                loading={loading}
                disabled={!isCheck}
              >
                Cadastrar
              </ButtonDefault>

          </Form>
        </div>
      </div>

      {modalTerms && (
        <ModalTerms 
          id="overlayModal"
          onClose={() => setModalTerms(!modalTerms)}
        />
      )}

      {modal && (
        <ModalDefault id="overlayModalDefault" onClose={() => setModal(!modal)}>
          <div className="cardModal">
            <div className="cardContent">
              <div className="infoModal">
                <img src={CancelImg} alt="cancelar" />
                <h1 className="errorModal">Erro</h1>
                <span className="messageModal">{errorForm}</span>
              </div>
              <div className="buttonModal">
                <button
                  className="buttonCancel"
                  onClick={() => setModal(!modal)}
                >
                  Tentar novamente
                </button>
              </div>
            </div>
          </div>
        </ModalDefault>
      )}
    </div>
  );
}
